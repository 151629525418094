import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { MessageService } from 'primeng/api';
import { jsPDF, TextOptionsLight } from 'jspdf';

import { PDFDocument } from 'pdf-lib';

import { AuthManagerService } from '../../../services/auth/auth-manager.service';
import { CaliforniaCompliancePageComponent } from '../compliance-pages/california-compliance-page/california-compliance-page.component';
import { DefaultCompliancePageComponent } from '../compliance-pages/default-compliance-page/default-compliance-page.component';
import { DeviceManagerService } from '../../../core/services/device-manager.service';
import { FormUtils } from '../../../shared-ui/utils/form-utils';
import { IllinoisCompliancePageComponent } from '../compliance-pages/illinois-compliance-page/illinois-compliance-page.component';
import { ImageApiService } from '../../../shared-api/images/image-api.service';
import { Location } from '../../../services/location/location.model';
import { LocationManagerService } from '../../../services/location/location-manager.service';
import { PdfSectionOutputResult } from '../../pdf-utils/pdf-section-output-result';
import { PricingType } from '../../../services/miscellaneous-part/miscellaneous-part.model';
import { PurchaseOrder } from '../../../services/purchase-order/purchase-order.model';
import { PurchaseOrderLineItem, PurchaseOrderLineItemMiscellaneous } from '../../../services/purchase-order/purchase-order-line-item.model';
import { PurchaseOrderManagerService } from '../../../services/purchase-order/purchase-order-manager.service';
import { PurchaseOrderTransactionPartType } from '../../../services/purchase-order/purchase-order-transaction';
import { TexasCompliancePageComponent } from '../compliance-pages/texas-compliance-page/texas-compliance-page.component';
import { Utils } from '../../../services/utils.service';
import { WestVirginiaCompliancePageComponent } from '../compliance-pages/west-virginia-compliance-page/west-virginia-compliance-page.component';
import { finalize } from 'rxjs/operators';

export enum SignaturePageType
{
    Default,
    California,
    Illinois,
    Texas,
    WestVirginia,
}

@Component({
    selector: 'ls-purchase-order-pdf',
    templateUrl: './purchase-order-pdf.component.html',
    styleUrls: [ './purchase-order-pdf.component.less' ]
})
export class PurchaseOrderPDFComponent implements OnInit
{
    @ViewChild('caCompliancePage', { static: false }) caCompliancePage: CaliforniaCompliancePageComponent;
    @ViewChild('ilCompliancePage', { static: false }) ilCompliancePage: IllinoisCompliancePageComponent;
    @ViewChild('txCompliancePage', { static: false }) txCompliancePage: TexasCompliancePageComponent;
    @ViewChild('wvCompliancePage', { static: false }) wvCompliancePage: WestVirginiaCompliancePageComponent;
    @ViewChild('defaultCompliancePage', { static: false }) defaultCompliancePage: DefaultCompliancePageComponent;

    @Output() signatureCompleted = new EventEmitter<boolean>();
    @Output() generationCompleted = new EventEmitter<boolean>();

    po: PurchaseOrder;
    pdf: jsPDF;
    needsSignature = false;
    result: PdfSectionOutputResult;

    pdfDimensions = {
        general: {
            marginLeft: 25,
            marginTop: 20,
            regularFontSize: 11,
            tableHeaderSize: 15,
            logoWidth: 162,
            logoHeight: 30,
            assayVendorLogoHeight: 50,
            bodyWidth: 555,
            pageHeight: 800,
            textPadding: 3,
            widthPerCharacter: 3.6,

            header: {
                fullRowHeight: 65,
                rowSpacing: 30,
                centerMargin: 20,
                headerRightColumnWidth: 100,
                singleInfoWidth: 100,
            },

            colors: {
                darkGray: '#AAA',
                lightGray: '#CCC',
                white: 'white',
                tableHeaderColor: '#AAA',
                alternateRowColor: '#CCC',
            }
        },
    };

    private signaturePdf: any;
    private pdfBrowserWindow: Window;
    private location: Location;
    protected signaturePageType: SignaturePageType;
    private showDetails: boolean;

    SignaturePageType = SignaturePageType;

    constructor(private deviceManager: DeviceManagerService,
                public authManager: AuthManagerService,
                private poManager: PurchaseOrderManagerService,
                private locationManager: LocationManagerService,
                private messageService: MessageService,
                private currencyPipe: CurrencyPipe) { }

    ngOnInit(): void
    {
        this.locationManager.getCurrentLocation().subscribe(location =>
        {
            this.location = location;
        });
    }

    initializeBrowserWindow(): void
    {
        this.pdfBrowserWindow = this.deviceManager.isiOS ? window.open() : null;
    }

    async create(emailToVendor: boolean, showDetails: boolean): Promise<void>
    {
        this.po = this.poManager.current;

        this.pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'px',
            format: 'letter'
        });
        this.pdfDimensions.general.bodyWidth = this.pdf.internal.pageSize.width - this.pdfDimensions.general.marginLeft * 2;
        this.pdfDimensions.general.pageHeight = this.pdf.internal.pageSize.height;

        this.result = new PdfSectionOutputResult();
        this.showDetails = showDetails;
        this.outputPageFooter();
        this.createHeader();
        const willOutputConverters = this.po.lineItemsAllConverters.length > 0;
        const willOutputMisc = this.po.lineItemsMiscellaneous.length > 0;
        this.createConvertersTable();
        if (willOutputConverters && willOutputMisc) this.addSpaceBetweenTables();
        this.createMiscTable();

        const pdfPages = [ this.pdf ];
        if (this.po.signaturePageUrl == null && this.po.location != null &&
            !this.authManager.currentUser.isAssayUser && !this.authManager.currentUser.exemptFromVendorLicenseRequirements)
        {
            // Save the existing PDF and generate just the signature page
            const savedResult = this.result;
            const savedPdf = this.pdf;
            this.result = new PdfSectionOutputResult();

            const signaturePdf = new jsPDF({
                orientation: 'portrait',
                unit: 'px',
                format: 'letter'
            });
            this.pdf = signaturePdf;

            switch (this.signaturePageType)
            {
                case SignaturePageType.California:
                    this.caCompliancePage.generateSignaturePage(this);
                    break;

                case SignaturePageType.Illinois:
                    this.ilCompliancePage.generateSignaturePage(this);
                    break;

                case SignaturePageType.Texas:
                    this.txCompliancePage.generateSignaturePage(this);
                    break;

                case SignaturePageType.WestVirginia:
                    this.wvCompliancePage.generateSignaturePage(this);
                    break;

                case SignaturePageType.Default:
                default:
                    this.defaultCompliancePage.generateSignaturePage(this);
                    break;
            }

            const blob = signaturePdf.output('blob');
            const file = new File([ blob ], `po_${this.po.id}_signature.pdf`);
            const timestamp = new Date().getTime();
            this.poManager.uploadSignaturePageAndLock(this.po.id, file).subscribe(async signatureResult =>
            {
                if (!signatureResult.success)
                {
                    this.messageService.add({ severity: 'error', summary: 'Upload Signature Page Error', detail: 'There was an unknown error upload signature page' });
                    return;
                }

                this.po.signaturePageUrl = `${signatureResult.url}?t=${timestamp}`;
                pdfPages.push(this.po.signaturePageUrl);

                // Generate extra pages if necessary
                let hasExtraPages = false;
                let extraPagesPdf: jsPDF;
                if (this.signaturePageType === SignaturePageType.California)
                {
                    this.result = new PdfSectionOutputResult();
                    extraPagesPdf = new jsPDF({
                        orientation: 'portrait',
                        unit: 'px',
                        format: 'letter'
                    });
                    this.pdf = extraPagesPdf;
                    hasExtraPages = this.caCompliancePage.generateExtraPages(this);
                }

                if (!hasExtraPages && this.po.extraPagesUrl == null)
                {
                    await this.openOrEmailPdf(pdfPages, emailToVendor);
                    return;
                }

                const extraPagesBlob = extraPagesPdf.output('blob');
                const extraPagesFile = new File([ extraPagesBlob ], `po_${this.po.id}_extra_pages.pdf`);
                const extraPagesCall = hasExtraPages ? this.poManager.uploadExtraPages(this.po.id, extraPagesFile)
                    : this.poManager.clearExtraPages(this.po.id);
                extraPagesCall.subscribe(async extraResult =>
                {
                    if (!extraResult.success)
                    {
                        this.messageService.add({ severity: 'error', summary: 'Upload Extra Pages Error', detail: 'There was an unknown error uploading extra pages.' });
                        this.poManager.unlock().subscribe();
                        return;
                    }
                    if (extraResult.url !== undefined)
                    {
                        this.po.extraPagesUrl = `${extraResult.url}?t=${timestamp}`;
                        pdfPages.push(this.po.extraPagesUrl);
                    }
                    else
                    {
                        this.po.extraPagesUrl = undefined;
                    }
                    this.result = savedResult;
                    this.pdf = savedPdf;
                    this.poManager.currentPOUpdated();
                    await this.openOrEmailPdf(pdfPages, emailToVendor);
                }, error =>
                {
                    this.messageService.add({ severity: 'error', summary: 'Upload Extra Pages Error', detail: error });
                });
            }, error =>
            {
                this.messageService.add({ severity: 'error', summary: 'Upload Signature Page Error', detail: 'Unknown error occurred.' });
                return;
            });

            return;
        }
        else
        {
            // We have responsibility to lock the PO and since that is not done with the signature block above (since we are not
            // creating signature pages), we need to lock it here
            if (!this.poManager.current.isLocked)
                this.poManager.lock().subscribe(() => this.generationCompleted.emit(true));
        }

        if (this.po.signaturePageUrl != null) pdfPages.push(this.po.signaturePageUrl);
        if (this.po.extraPagesUrl != null) pdfPages.push(this.po.extraPagesUrl);
        await this.openOrEmailPdf(pdfPages, emailToVendor);
    }

    private async openOrEmailPdf(pdfPages: jsPDF[], emailToVendor: boolean): Promise<void>
    {
        const base64 = await PurchaseOrderPDFComponent.combinePDFs(pdfPages);
        if (!emailToVendor)
        {
            const blob = ImageApiService.b64toBlob(base64, 'application/pdf');
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
            // if (this.deviceManager.isiOS)
            //     this.pdfBrowserWindow.location.href = blobUrl;
            // else
            //     window.open(blobUrl, '_blank');
            this.generationCompleted.emit(true);
            return;
        }
        this.pdfBrowserWindow = null;

        const base64Pdf = base64.substring(base64.indexOf('base64,') + 7);
        this.poManager.emailPdf(base64Pdf).subscribe(() =>
            {
                this.messageService.add({ severity: 'success', summary: 'Success', detail: 'PDF has been emailed.' });
                this.generationCompleted.emit(true);
            },
            () =>
            {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to email PDF.' });
                this.generationCompleted.emit(false);
            });

    }
    // tslint:disable-next-line:member-ordering
    static async combinePDFs(pdfs: any[]): Promise<string>
    {
        const pdfDoc = await PDFDocument.create();
        const buffers: ArrayBuffer[] = [];
        // tslint:disable-next-line:prefer-for-of
        for (let iPdf = 0; iPdf < pdfs.length; iPdf++)
        {
            if (typeof pdfs[iPdf] === 'string')
                buffers.push(await fetch(pdfs[iPdf]).then(res => res.arrayBuffer()));
            else
                buffers.push(pdfs[iPdf].output('arraybuffer'));
        }

        for (const buffer of buffers)
        {
            const subDoc = await PDFDocument.load(buffer);
            const pageIndices = subDoc.getPageIndices();
            for (const index of pageIndices)
            {
                const [pages] = await pdfDoc.copyPages(subDoc, [index]);
                pdfDoc.addPage(pages);
            }
        }
        return pdfDoc.saveAsBase64({ dataUri: true });
    }

    //region Header Section
    private createHeader(): void
    {
        const purchaseOrderDate = FormUtils.formatDateForDisplay(this.po.purchaseDate);

        const general = this.pdfDimensions.general;
        const header = general.header;
        const colors = general.colors;

        this.pdf.setFontSize(general.regularFontSize);

        // Buyer
        let sectionBounds = {
            left: general.marginLeft, top: general.marginTop, right: this.center - header.centerMargin / 2,
            bottom: general.marginTop + 4 * this.lineHeight
        };
        if (this.po.location != null || this.po.vendor.parentVendor != null)
        {
            this.pdf.text('Buyer:', sectionBounds.left, sectionBounds.top - 5);
            this.drawSectionArea(sectionBounds, colors.white);
            const buyerName = this.po.buyerName;

            this.addLineToSection(buyerName, sectionBounds, 1);
            if (this.po.location != null)
            {
                this.addLineToSection(this.location.subsidiary.displayName + ' - ' +
                    (this.authManager.currentUser.purchaseOrderLocationName || this.po.location.name), sectionBounds, 2);
                this.addLineToSection(this.authManager.currentUser.purchaseOrderAddress || this.po.location.address, sectionBounds, 3);
                this.addLineToSection(this.authManager.currentUser.purchaseOrderCityStateZip || this.po.location.cityStateZip,
                    sectionBounds, 4);
            }
            else
            {
                const parentVendor = this.po.vendor.parentVendor;
                this.addLineToSection(parentVendor.name, sectionBounds, 2);
                this.addLineToSection(parentVendor.address, sectionBounds, 3);
                this.addLineToSection(`${parentVendor.cityStateZip}`, sectionBounds, 4);
            }
        }

        // Seller
        sectionBounds.top = sectionBounds.bottom + header.rowSpacing + this.lineHeight;
        sectionBounds.bottom = sectionBounds.top + 3 * this.lineHeight;
        this.pdf.text('Seller:', sectionBounds.left, sectionBounds.top - 5);
        this.drawSectionArea(sectionBounds, colors.white);
        this.addLineToSection(this.po.vendorName, sectionBounds, 1);
        if (this.po.vendor.address != null) this.addLineToSection(this.po.vendor.address, sectionBounds, 2);
        this.addLineToSection(this.po.vendor.cityStateZip, sectionBounds, 3);

        // PO Number and Date
        sectionBounds.bottom = sectionBounds.top + this.lineHeight;
        sectionBounds.left = this.center + general.bodyWidth / 2 - header.singleInfoWidth;
        sectionBounds.right = this.center + general.bodyWidth / 2;
        this.pdf.text('PO #:', sectionBounds.left - 35, sectionBounds.top + 15);
        this.drawSectionArea(sectionBounds, colors.white);
        this.addLineToSection(this.po.number, sectionBounds, 1);
        sectionBounds.top = sectionBounds.bottom + 12;
        sectionBounds.bottom = sectionBounds.top + this.lineHeight;
        this.pdf.text('Date:', sectionBounds.left - 35, sectionBounds.top + 15);
        this.drawSectionArea(sectionBounds, colors.white);
        this.addLineToSection(purchaseOrderDate, sectionBounds, 1);

        // Total Price
        const totalPriceText = `Total: ${this.currencyPipe.transform(this.po.totalPrice, 'USD')}`;
        const totalTop = sectionBounds.bottom + 30;
        this.pdf.text(totalPriceText, general.marginLeft + general.bodyWidth - this.textWidth(totalPriceText) - general.textPadding,
            totalTop);
        this.result.bottomOfOutput = totalTop + 20;

        // Logo
        if (this.authManager.currentUser.isAssayUser)
        {
            const logoImageUrl = this.po.vendor.logoImageUrl != null ? this.po.vendor.logoImageUrl
                : this.po.vendor.parentVendor != null ? this.po.vendor.parentVendor.logoImageUrl : null;
            if (logoImageUrl != null)
            {
                const imageProperties = this.pdf.getImageProperties(logoImageUrl);
                const sizeRatio = imageProperties.width / imageProperties.height;
                const logoHeight = general.assayVendorLogoHeight;
                const logoWidth = logoHeight * sizeRatio;
                sectionBounds = { left: this.center + general.bodyWidth / 2 - logoWidth - 10, top: general.marginTop + 2,
                    right: 0, bottom: 0 };
                this.pdf.addImage(logoImageUrl, 'PNG', sectionBounds.left, sectionBounds.top, logoWidth, logoHeight);
            }
        }
        else
        {
            sectionBounds = { left: this.center + general.bodyWidth / 2 - general.logoWidth - 10, top: general.marginTop + 12,
                right: 0, bottom: 0 };
            this.pdf.addImage(this.location.subsidiary.name !== 'Legend' ? 'assets/images/elemental/logo_horizontal.png?q=2' : 'assets/images/logo_simple.png?q=2', 'PNG',
                sectionBounds.left, sectionBounds.top, general.logoWidth, general.logoHeight);
        }
    }

    private drawSectionArea(sectionBounds, backgroundColor: string): void
    {
        this.pdf.setDrawColor(0, 0, 0);
        this.pdf.setFillColor(backgroundColor);
        this.pdf.roundedRect(sectionBounds.left, sectionBounds.top,
            sectionBounds.right - sectionBounds.left, sectionBounds.bottom - sectionBounds.top + 8, 5, 5, 'DF');
    }

    private addLineToSection(text: string, sectionBounds: any, lineNumber: number): void
    {
        this.pdf.text(text, sectionBounds.left + this.pdfDimensions.general.textPadding,
            sectionBounds.top + this.lineHeight * lineNumber);
    }
    //endregion

    //region Converters, Custom, No-Numbers, and Categories tables
    private createConvertersTable(): boolean
    {
        if (this.po.lineItemsAllConverters.length === 0) return false;

        this.checkForBottomOfPage(this.lineHeight * 1.5 + this.tableLineHeight * 2);
        this.createTableTitle(PurchaseOrderTransactionPartType.Converter);
        if (this.showDetails)
        {
            this.outputLineItems(this.po.lineItemsAllConverters, PurchaseOrderTransactionPartType.Converter);
            return;
        }

        // Display converter group lines
        const grouped = Utils.group(this.po.lineItemsAllConverters, li => li.converterGroupName);
        const lines = grouped.map(group => ({
            type: PurchaseOrderTransactionPartType.Converter,
            converterGroup: group.key,
            quantity: Utils.sum(group.data, 'quantity'),
            totalPrice: Utils.sum(group.data, 'totalPrice'),
        }));
        this.createGroupLineItems(lines, PurchaseOrderTransactionPartType.Converter);
        return true;
    }

    private createCustomTable(): boolean
    {
        if (this.po.lineItemsCustom.length === 0) return false;

        this.checkForBottomOfPage(this.lineHeight * 1.5 + this.tableLineHeight * 2);
        this.createTableTitle(PurchaseOrderTransactionPartType.Custom);
        if (this.showDetails)
        {
            this.outputLineItems(this.po.lineItemsCustom, PurchaseOrderTransactionPartType.Custom);
            return;
        }

        // Display converter group lines
        const grouped = Utils.group(this.po.lineItemsCustom, li => li.converterGroupName);
        const lines = grouped.map(group => ({
            converterGroup: group.key,
            quantity: Utils.sum(group.data, 'quantity'),
            totalPrice: Utils.sum(group.data, 'totalPrice'),
        }));
        this.createGroupLineItems(lines, PurchaseOrderTransactionPartType.Custom);
        return true;
    }

    private createCategoryTable(): boolean
    {
        if (this.po.lineItemsCategory.length === 0) return false;

        this.checkForBottomOfPage(this.lineHeight * 1.5 + this.tableLineHeight * 2);
        this.createTableTitle(PurchaseOrderTransactionPartType.Category);
        if (this.showDetails)
        {
            this.outputLineItems(this.po.lineItemsCategory, PurchaseOrderTransactionPartType.Category);
            return;
        }

        // Display converter group lines
        const grouped = Utils.group(this.po.lineItemsCategory, li => li.converterGroupName);
        const lines = grouped.map(group => ({
            converterGroup: group.key,
            quantity: Utils.sum(group.data, 'quantity'),
            totalPrice: Utils.sum(group.data, 'totalPrice'),
        }));
        this.createGroupLineItems(lines, PurchaseOrderTransactionPartType.Category);
        return true;
    }

    private createNoNumberTable(): boolean
    {
        if (this.po.lineItemsNoNumber.length === 0) return false;

        this.checkForBottomOfPage(this.lineHeight * 1.5 + this.tableLineHeight * 2);
        this.createTableTitle(PurchaseOrderTransactionPartType.NoNumber);
        if (this.showDetails)
        {
            this.outputLineItems(this.po.lineItemsNoNumber, PurchaseOrderTransactionPartType.NoNumber);
            return;
        }

        // Display converter group lines
        const grouped = Utils.group(this.po.lineItemsNoNumber, li => li.converterGroupName);
        const lines = grouped.map(group => ({
            converterGroup: group.key,
            quantity: Utils.sum(group.data, 'quantity'),
            totalPrice: Utils.sum(group.data, 'totalPrice'),
        }));
        this.createGroupLineItems(lines, PurchaseOrderTransactionPartType.NoNumber);
        return true;
    }

    private createMiscTable(): boolean
    {
        if (this.po.lineItemsMiscellaneous.length === 0) return false;

        this.checkForBottomOfPage(this.lineHeight * 1.5 + this.tableLineHeight * 2);
        this.createTableTitle(PurchaseOrderTransactionPartType.Miscellaneous);
        this.outputLineItems(this.po.lineItemsMiscellaneous, PurchaseOrderTransactionPartType.Miscellaneous);
        return true;
    }

    private outputLineItems(lineItems: PurchaseOrderLineItem[] | PurchaseOrderLineItemMiscellaneous[],
                            type: PurchaseOrderTransactionPartType): void
    {
        const columns = type === PurchaseOrderTransactionPartType.Miscellaneous ? [
                { title: 'Type', widthPercent: 25, align: 'left',
                    calcFunc: (item) => item.pricingType === PricingType.ByPiece ? 'By Piece' : 'By Pound' },
                { title: 'Description', widthPercent: 35, align: 'left', propertyName: 'description' },
                { title: 'Quantity', widthPercent: 10, align: 'center', propertyName: 'quantity' },
                { title: 'Price Per Item', widthPercent: 15, align: 'right',
                    calcFunc: (item) => this.currencyPipe.transform(item.pricePerItem, 'USD') },
                { title: 'Total Price', widthPercent: 15, align: 'right',
                    calcFunc: (item): string => this.currencyPipe.transform(item.totalPrice, 'USD') },
            ] :
            [
                { title: 'Part Number', widthPercent: 30, align: 'left',
                    calcFunc: (item): string => item.type === PurchaseOrderTransactionPartType.Custom ? `${item.converterGroupName} Custom`
                        : item.converterPartNumberReadable },
                { title: 'Manufacturer', widthPercent: 15, align: 'left', propertyName: 'converterGroupName' },
                { title: 'Metal Quantity', widthPercent: 16, align: 'center', propertyName: 'levelPercentageText' },
                { title: 'Quantity', widthPercent: 10, align: 'center', propertyName: 'quantity' },
                { title: 'Price / Item', widthPercent: 14, align: 'right',
                    calcFunc: (item): string => this.currencyPipe.transform(item.pricePerItem, 'USD', 'symbol')
                },
                { title: 'Total Price', widthPercent: 15, align: 'right',
                    calcFunc: (item): string => this.currencyPipe.transform(item.totalPrice, 'USD', 'symbol')
                },
            ];

        this.createTableHeader(columns);
        lineItems.forEach((lineItem, index) =>
        {
            if (index % 2 !== 0)
                this.drawRectanglesForTableColumns(columns, this.result.bottomOfOutput, null,
                    this.pdfDimensions.general.colors.alternateRowColor);
            this.outputTableLine(lineItem, columns);
            this.checkForBottomOfPage(this.tableLineHeight);
        });
        this.createSubtotalLine(type, lineItems, columns);
        this.checkForBottomOfPage(this.tableLineHeight);
    }

    private createGroupLineItems(lines: any[], type: PurchaseOrderTransactionPartType): void
    {
        const columns = [
                { title: 'Manufacturer', widthPercent: 35, align: 'left', propertyName: 'converterGroup' },
                { title: 'Quantity', widthPercent: 15, align: 'center', propertyName: 'quantity' },
                { title: 'Price Per Item', widthPercent: 25, align: 'right',
                    calcFunc: (item): string => this.currencyPipe.transform(item.totalPrice / item.quantity, 'USD', 'symbol') },
                { title: 'Total Price', widthPercent: 25, align: 'right',
                    calcFunc: (item): string => this.currencyPipe.transform(item.totalPrice, 'USD', 'symbol') },
            ];

        this.createTableHeader(columns);
        lines.forEach((line, index) =>
        {
            if (index % 2 !== 0)
                this.drawRectanglesForTableColumns(columns, this.result.bottomOfOutput, null,
                    this.pdfDimensions.general.colors.alternateRowColor);
            this.outputTableLine(line, columns);
            this.checkForBottomOfPage(this.tableLineHeight);
        });
        this.createSubtotalLine(type, lines, columns);
        this.checkForBottomOfPage(this.tableLineHeight);
    }
    //endregion

    //region General Table Output
    createTableHeader(columns: any, drawBorders = true, useFill = true, tableWidth = this.pdfDimensions.general.bodyWidth): void
    {
        const general = this.pdfDimensions.general;
        const colors = general.colors;

        // Draw the header
        this.pdf.setFontSize(general.regularFontSize);
        this.drawRectanglesForTableColumns(columns, this.result.bottomOfOutput, drawBorders ? 'black' : null,
            useFill ? colors.tableHeaderColor : colors.white, tableWidth );
        let currentColumnOffset = general.marginLeft;
        const totalColumnWidthPercent = Utils.sum(columns, 'widthPercent');
        for (const col of columns)
        {
            const columnWidth = tableWidth * (col.widthPercent / totalColumnWidthPercent);
            this.outputTableValue(col.title, col, totalColumnWidthPercent, currentColumnOffset, this.result.bottomOfOutput);
            currentColumnOffset += columnWidth;
        }
        this.result.bottomOfOutput += this.tableLineHeight;
    }

    private outputTableLine(line: any, columns: any, tableWidth = this.pdfDimensions.general.bodyWidth): void
    {
        const general = this.pdfDimensions.general;
        let currentColumnOffset = general.marginLeft;
        const totalColumnWidthPercent = Utils.sum(columns, 'widthPercent');
        for (const col of columns)
        {
            const text = col.propertyName != null ? line[col.propertyName].toString() : col.calcFunc(line);
            const columnWidth = tableWidth * (col.widthPercent / totalColumnWidthPercent);
            if (line instanceof PurchaseOrderLineItemMiscellaneous ||
                line.type === PurchaseOrderTransactionPartType.Converter ||
                line.type === PurchaseOrderTransactionPartType.NoNumber ||
                line.type === PurchaseOrderTransactionPartType.Category ||
                (line.type === PurchaseOrderTransactionPartType.Custom && col.title !== 'Metal Quantity'))
            {
                this.outputTableValue(text, col, totalColumnWidthPercent, currentColumnOffset, this.result.bottomOfOutput);
            }
            currentColumnOffset += columnWidth;
        }
        this.result.bottomOfOutput += this.tableLineHeight;
    }

    private createSubtotalLine(type: PurchaseOrderTransactionPartType, lines: any, columns: any[]): void
    {
        const general = this.pdfDimensions.general;

        // this.result.bottomOfOutput += this.tableLineHeight / 2;
        this.checkForBottomOfPage();

        this.drawRectanglesForTableColumns(columns, this.result.bottomOfOutput, 'black', general.colors.darkGray);
        let currentColumnOffset = general.marginLeft;
        const quantity = Utils.sum(lines, 'quantity');
        const totalPrice = Utils.sum(lines, 'totalPrice');
        const totalColumnWidthPercent = Utils.sum(columns, 'widthPercent');
        const decimals = '.2-2';
        for (let iColumn = 0; iColumn < columns.length; iColumn++)
        {
            const col = columns[iColumn];
            const text = iColumn === 0 ? PurchaseOrderPDFComponent.tableTitle(type) + ' SUBTOTAL' :
                col.title === 'Quantity' ? quantity.toString() :
                    col.title === 'Price Per Item' ? this.currencyPipe.transform(totalPrice / quantity, 'USD', 'symbol', decimals) :
                        col.title === 'Total Price' ? this.currencyPipe.transform(totalPrice, 'USD', 'symbol', decimals) : null;
            const columnWidth = general.bodyWidth * (col.widthPercent / totalColumnWidthPercent);
            if (text != null)
                this.outputTableValue(text, col, totalColumnWidthPercent, currentColumnOffset, this.result.bottomOfOutput);
            currentColumnOffset += columnWidth;
        }
    }

    private drawRectanglesForTableColumns(columns: any, top: number, drawColor = 'black', fillColor = 'white',
                                          tableWidth = this.pdfDimensions.general.bodyWidth): void
    {
        this.pdf.setDrawColor(drawColor == null ? fillColor : drawColor);
        this.pdf.setFillColor(fillColor);
        this.pdf.setFontSize(this.pdfDimensions.general.regularFontSize);
        let currentColumnOffset = this.pdfDimensions.general.marginLeft;
        const totalColumnWidthPercent = Utils.sum(columns, 'widthPercent');
        for (const col of columns)
        {
            const columnWidth = tableWidth * (col.widthPercent / totalColumnWidthPercent);
            this.pdf.rect(currentColumnOffset, top, columnWidth, this.tableLineHeight, 'DF');
            currentColumnOffset += columnWidth;
        }
    }

    private outputTableValue(text: string, column: any, totalColumnWidthPercent: number, left: number, top: number): void
    {
        const general = this.pdfDimensions.general;
        const columnWidth = general.bodyWidth * (column.widthPercent / totalColumnWidthPercent);
        let truncatedText = text;
        if (this.textWidth(truncatedText) > columnWidth) {
            // Truncate the text
            const ellipsisWidth = this.textWidth('...');
            while (this.textWidth(truncatedText) + ellipsisWidth > columnWidth)
            {
                truncatedText = truncatedText.substring(0, truncatedText.length - 1);
            }
            truncatedText += '...';
        }
        const truncatedWidth = this.textWidth(truncatedText);
        const textLeft = column.align === 'left' ? left + 3 :
            column.align === 'right' ? left + columnWidth - truncatedWidth - general.textPadding :
                left + columnWidth / 2 - truncatedWidth / 2;
        this.pdf.text(truncatedText, textLeft, top + this.lineHeight);
    }

    private addSpaceBetweenTables(): void
    {
        if (!this.checkForBottomOfPage(this.tableLineHeight * 3))
            this.result.bottomOfOutput += this.tableLineHeight * 3;
    }
    //endregion

    //region Compliance pages
    captureSignature(signaturePageType: SignaturePageType): void
    {
        this.po = this.poManager.current;

        // Simply fire event that signature was completed if it does not require one or already has one
        if (this.po.signaturePageUrl != null || this.po.location == null ||
            this.authManager.currentUser.isAssayUser || this.authManager.currentUser.exemptFromVendorLicenseRequirements)
        {
            this.signatureCompleted.emit(true);
            return;
        }

        this.signaturePageType = signaturePageType;
        this.needsSignature = true;
    }

    onSignatureCompleted(completed: boolean): void
    {
        this.signatureCompleted.emit(completed);
    }

    onSignaturePdfGenerated(completed: boolean): void
    {
        // this.signatureCompleted.emit(completed);
    }

    // get hasCompliancePage(): boolean { return this.poManager.current != null && this.poManager.current.location != null; }
    //
    // get hasDefaultCompliancePage(): boolean {
    //     const state = this.poManager.current.location.state.toLowerCase();
    //     return this.hasCompliancePage && state !== 'ca' && state !== 'il' && state !== 'tx';
    // }

    /* tslint:disable:max-line-length */
    private createTexasCompliancePage(signatureImage: any): void
    {
        this.createNewPdfPage(false);
        this.outputComplianceHeader();

        // PO Information
        // Use "table headers" to create the pseudo-table
        this.result.bottomOfOutput -= this.lineHeight * 0.5;
        const columns = [
            { title: 'PO #', widthPercent: 50, align: 'left' },
            { title: 'Total Purchase', widthPercent: 50, align: 'left' },
        ];
        this.createTableHeader(columns, true, false, this.pdfDimensions.general.bodyWidth * 0.5);
        columns[0].title = this.po.number;
        columns[1].title = this.currencyPipe.transform(this.po.totalConvertersPrice, 'USD');
        this.createTableHeader(columns, true, false, this.pdfDimensions.general.bodyWidth * 0.5);
        this.pdf.setFontSize(12);

        // Buyer
        this.result.bottomOfOutput += this.lineHeight * 3;
        this.outputTextLine('Buyer:');
        this.result.bottomOfOutput += this.lineHeight;
        this.outputTextLine(this.po.buyerName);
        this.outputTextLine(this.po.location.address);
        this.outputTextLine(`${this.po.location.city}, ${this.po.location.state}  ${this.po.location.zip}`);

        // Text
        this.result.bottomOfOutput += this.lineHeight * 2;
        this.outputTextLine('I, the undersigned, hereby certify that I am the legal owner of all the merchandise sold. I have');
        this.outputTextLine('an active license, certificate, registration, documentation showing my business entity status at');
        this.outputTextLine('point of sale. I sell regulated material and/or regulated metal in the ordinary course of my');
        this.outputTextLine('business and I acknowledge this receipt as payment in full.');

        // Seller
        this.result.bottomOfOutput += this.lineHeight * 2;
        this.outputTextLine('Seller:');
        this.result.bottomOfOutput += this.lineHeight;
        this.outputTextLine(this.po.vendor.name);
        this.outputTextLine(this.po.vendor.address);
        this.outputTextLine(`${this.po.vendor.city}, ${this.po.vendor.state}  ${this.po.vendor.zip}`);

        // Signature block
        this.result.bottomOfOutput += this.lineHeight * 3;
        this.outputTextLine('Signature ________________________________________            Date ___________________ ');
        this.result.bottomOfOutput += this.lineHeight * 2;
        this.outputTextLine('Print ____________________________________________ ');
    }

    outputComplianceHeader(text: string = 'Purchase Agreement'): void
    {
        this.pdf.setFont('times');
        this.pdf.setFontSize(20);
        this.pdf.text(text, this.center - this.textWidth(text) / 2, this.result.bottomOfOutput);
        this.result.bottomOfOutput += this.lineHeight * 1.5;
    }
    /* tslint:enable:max-line-length */
    //endregion

    //region Misc.
    private createTableTitle(type: PurchaseOrderTransactionPartType): void
    {
        if (this.result.bottomOfOutput > this.bottomOfOutputArea) this.createNewPdfPage();

        this.pdf.setFontSize(this.pdfDimensions.general.tableHeaderSize);
        this.pdf.setDrawColor(0, 0, 0);
        this.pdf.text(PurchaseOrderPDFComponent.tableTitle(type), this.pdfDimensions.general.marginLeft, this.result.bottomOfOutput);
        this.result.bottomOfOutput += 5;
    }

    // tslint:disable-next-line:member-ordering
    private static tableTitle(type: PurchaseOrderTransactionPartType): string
    {
        switch (type)
        {
            case PurchaseOrderTransactionPartType.Converter: return 'Converters';
            case PurchaseOrderTransactionPartType.Custom: return 'Custom Converters';
            case PurchaseOrderTransactionPartType.NoNumber: return 'No-Number Converters';
            case PurchaseOrderTransactionPartType.Category: return 'Category Converters';
            case PurchaseOrderTransactionPartType.Miscellaneous: return 'Non-Ferrous';
        }
    }

    private checkForBottomOfPage(lineHeight = 0): boolean
    {
        const needsPageBreak = this.result.bottomOfOutput + lineHeight >= this.bottomOfOutputArea;
        if (needsPageBreak)
            this.createNewPdfPage();
        return needsPageBreak;
    }

    createNewPdfPage(includeFooter = true): void
    {
        this.pdf.addPage();

        this.result.bottomOfOutput = this.pdfDimensions.general.marginTop;
        this.result.currentPageNumber++;
        if (includeFooter) this.outputPageFooter();
    }

    outputText(text: string, indent = 0, options: TextOptionsLight = null): void
    {
        this.pdf.text(text, this.pdfDimensions.general.marginLeft + indent, this.result.bottomOfOutput, options);
    }

    outputTextLine(text: string, indent = 0, options: TextOptionsLight = null): void
    {
        this.pdf.text(text, this.pdfDimensions.general.marginLeft + indent, this.result.bottomOfOutput, options);
        this.result.bottomOfOutput += this.lineHeight;
    }

    outputTextLines(lines: string[], indent = 0, hangingIndent = 0): void
    {
        if (hangingIndent === 0)
        {
            this.pdf.text(lines, this.pdfDimensions.general.marginLeft + indent, this.result.bottomOfOutput);
            this.result.bottomOfOutput += this.lineHeight * lines.length;
        }
        else
        {
            this.pdf.text(lines[0], this.pdfDimensions.general.marginLeft + indent, this.result.bottomOfOutput);
            this.result.bottomOfOutput += this.lineHeight;
            this.pdf.text(lines.slice(1), this.pdfDimensions.general.marginLeft + hangingIndent, this.result.bottomOfOutput);
            this.result.bottomOfOutput += this.lineHeight * (lines.length - 1);
        }
    }

    outputPageFooter(): void
    {
        this.pdf.setFontSize(this.pdfDimensions.general.regularFontSize);
        const pageText = `Page ${this.result.currentPageNumber}`;
        this.pdf.text(pageText, this.pdfDimensions.general.marginLeft + this.pdfDimensions.general.bodyWidth -
            this.textWidth(pageText), this.pdfDimensions.general.pageHeight - 15);
    }

    outputCheckbox(left: number, checked: boolean): void
    {
        this.pdf.rect(this.leftMargin + left, this.result.bottomOfOutput - 8, 10, 10);
        if (checked)
        {
            this.pdf.line(this.leftMargin + left, this.result.bottomOfOutput - 8,
                this.leftMargin + left + 10, this.result.bottomOfOutput + 2);
            this.pdf.line(this.leftMargin + left + 10, this.result.bottomOfOutput - 8,
                this.leftMargin + left, this.result.bottomOfOutput + 2);
        }
    }

    outputImage(image: any, type: string, left: number, width: number, height: number): void
    {
        if (image == null) return;
        this.pdf.addImage(image, type, left, this.result.bottomOfOutput - height, width, height);
    }

    get bottomOfOutputArea(): number
    {
        return this.pdfDimensions.general.pageHeight - this.lineHeight * 2.5;
    }

    textWidth(text: string): number
    {
        return this.pdf.getTextWidth(text);
    }

    get top(): number { return this.pdfDimensions.general.marginTop; }
    get leftMargin(): number { return this.pdfDimensions.general.marginLeft; }
    get center(): number { return this.pdfDimensions.general.marginLeft + this.pdfDimensions.general.bodyWidth / 2; }
    get rightMargin(): number { return this.pdfDimensions.general.marginLeft + this.pdfDimensions.general.bodyWidth; }
    get width(): number { return this.pdfDimensions.general.bodyWidth; }
    get tableLineHeight(): number { return this.pdf.getFontSize() + 4; }
    get lineHeight(): number { return this.pdf.getFontSize(); }

    // tslint:disable-next-line:member-ordering
    static removeUnderscores(text: string): string
    {
        return text.replace(/_/g, '');
    }
    //endregion
}
