import { MetalPrices } from '../pricing/metal-prices.model';
import { ProcessorTerms } from '../pricing/processor-terms.model';
import { ProfitTierGroup } from '../pricing/profit-tier.model';
import { Role } from '../auth/role.model';

export enum UserNotificationMethod
{
    None = 0,
    Email = 1,
    Text = 2
}

export class User
{
    public static LIMITED_ADMIN_LOCATION_ID = -1;

    static NotificationOptions = [
        { label: 'None', value: UserNotificationMethod.None },
        { label: 'Email', value: UserNotificationMethod.Email },
        { label: 'Text', value: UserNotificationMethod.Text },
    ];

    public id: string;
    public allowedNumberOfSearchesPerHour: number;
    public email: string;
    public exemptFromVendorLicenseRequirements = false;
    public firstName: string;
    public hedgeId: number;
    public isAssayVendor = false;
    public hasAssayAsAdditionalLocation = false;
    public lastName: string;
    public locationId: number;
    public locationName: string;
    public metalPrices: MetalPrices[];
    public pricingNotificationMethod = UserNotificationMethod.None;
    public receiveAutoUpdatesFromMarketChanges = false;
    public processorTerms: ProcessorTerms[];
    public profitTierGroups: ProfitTierGroup[] = [];
    public phoneNumber: string;
    public permissions: number[] = [];
    public roles: string[] = [];
    public additionalLocationIds: number[] = [];
    public twoFactorEnabled = true;
    public notifyUserOfMarketChanges = false;
    public useFlatPricing = false;
    public vendorId: number;
    public vendorName: string;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
            if (json.metalPrices != null) this.metalPrices = json.metalPrices.map(item => new MetalPrices(item));
            if (json.processorTerms != null) this.processorTerms = json.processorTerms.map(item => new ProcessorTerms(item));
            if (json.profitTierGroups != null) this.profitTierGroups = json.profitTierGroups.map(item => new ProfitTierGroup(item));
        }
    }

    get fullName(): string { return `${this.firstName == null ? '' : this.firstName}` +
        (this.lastName == null ? '' : ` ${this.lastName}`); }

    get roleIconHTML(): string
    {
        return '<div class="role-icons">' +
            (this.isGlobalAdmin ? Role.iconHTML('GLOBALADMIN') : '') +
            (this.isLocationAdmin ? Role.iconHTML('LOCATIONADMIN') : '') +
            (this.isLocationAccountant ? Role.iconHTML('LOCATIONACCOUNTANT') : '') +
            (this.isLimitedAdmin ? Role.iconHTML('LIMITEDADMIN') : '') +
            (this.isVendorAdmin ? Role.iconHTML('VENDORADMIN') : '') +
            (this.isBuyer ? Role.iconHTML('BUYER') : '') +
            '</div>';
    }

    get rolesString(): string { return this.roles.join(' '); }

    get isGlobalAdmin(): boolean { return User.isGlobalAdmin(this.roles); }
    get isLocationAdmin(): boolean { return User.isLocationAdmin(this.roles); }
    get isLimitedAdmin(): boolean { return User.isLimitedAdmin(this.roles); }
    get isLocationAccountant(): boolean { return User.isLocationAccountant(this.roles); }
    get isVendorAdmin(): boolean { return User.isVendorAdmin(this.roles); }
    get isBuyer(): boolean { return User.isBuyer(this.roles); }
    // get associatedVendorName(): string { return this.supplierParameters == null ? null : this.supplierParameters.associatedVendorName; }
    // get supplierPricesLastUpdated(): Date { return this.supplierParameters == null ? null : this.supplierParameters.lastUpdated; }

    static isGlobalAdmin(roles: string[]): boolean { return roles.findIndex(r => r.toUpperCase() === 'GLOBALADMIN' ) !== -1; }
    static isLocationAdmin(roles: string[]): boolean { return roles.findIndex(r => r.toUpperCase() === 'LOCATIONADMIN' ) !== -1; }
    static isLimitedAdmin(roles: string[]): boolean { return roles.findIndex(r => r.toUpperCase() === 'LIMITEDADMIN' ) !== -1; }
    static isLocationAccountant(roles: string[]): boolean { return roles.findIndex(r => r.toUpperCase() === 'LOCATIONACCOUNTANT' ) !== -1; }
    static isVendorAdmin(roles: string[]): boolean { return roles.findIndex(r => r.toUpperCase() === 'VENDORADMIN' ) !== -1; }
    static isBuyer(roles: string[]): boolean { return roles.findIndex(r => r.toUpperCase() === 'BUYER' ) !== -1; }
}
