import { PricingType } from '../miscellaneous-part/miscellaneous-part.model';
import { PurchaseOrderTransactionPartType } from './purchase-order-transaction';
import { Utils } from '../utils.service';

export class PurchaseOrderLineItem
{
    static levelPercentageDropdownOptions = [
        { label: 'Full', value: 1 },
        { label: '90%', value: 0.9 },
        { label: '75%', value: 0.75 },
        { label: 'Half', value: 0.5 },
        { label: '25%', value: 0.25 },
        { label: '10%', value: 0.1 }
    ];

    id: number;
    byPound = false;
    converterGroupName: string;
    converterId: number;
    converterPartNumber: string;
    quantity: number;
    categoryHasCustomPrices = false;
    lastUpdated: Date;
    levelPercentage = 1;
    pricePerItem: number;
    totalPrice: number;
    type: PurchaseOrderTransactionPartType;

    constructor(json: any = null, type: PurchaseOrderTransactionPartType = null)
    {
        if (json) {
            Object.assign(this, json);
            if (json.lastUpdated != null) this.lastUpdated = Utils.convertApiUtcDateStringToDate(json.lastUpdated);
        }
        if (type != null) this.type = type;
    }

    get levelPercentageText(): string
    {
        return PurchaseOrderLineItem.getLevelPercentageText(this.levelPercentage, this.byPound);
    }

    get converterPartNumberReadable(): string { return this.converterPartNumber.replace('&ast;', '*'); }

    static getLevelPercentageText(levelPercentage: number, byPound: boolean): string
    {
        if (byPound) return levelPercentage.toString() + (levelPercentage === 1 ? ' lb.' : ' lbs.');
        if (levelPercentage === 0) return 'Empty';
        if (levelPercentage === 0.10) return '10%';
        if (levelPercentage === 0.25) return '25%';
        if (levelPercentage === 0.50) return 'Half';
        if (levelPercentage === 0.75) return '75%';
        if (levelPercentage === 0.90) return '90%';
        return 'Full';
    }

    updateTotalPrice(): number
    {
        this.totalPrice = Math.round(this.quantity * this.levelPercentage * this.pricePerItem);
        return this.totalPrice;
    }
}

export class PurchaseOrderLineItemMiscellaneous
{
    id: number;
    byPound = false;
    partId: number;
    description: string;
    lastUpdated: Date;
    quantity: number;
    pricePerItem: number;
    pricingType = PricingType.ByPiece;
    totalPrice: number;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
            if (json.lastUpdated != null) this.lastUpdated = Utils.convertApiUtcDateStringToDate(json.lastUpdated);
        }
    }

    updateTotalPrice(): number
    {
        this.totalPrice = this.quantity * this.pricePerItem;
        return this.totalPrice;
    }
}
