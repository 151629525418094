import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthManagerService } from '../../services/auth/auth-manager.service';
import { map, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '../../../environments/environment';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class AuthGuardService  implements OnDestroy
{
    private restrictedPaths = [
        { path: '/admin/upload-parts', requiredPermissions: [ 'isGlobalAdmin' ] },
        { path: '/admin/vendors-users', requiredPermissions: [ 'editUserList', 'editUserProperties', 'editUserPermissions', 'deleteVendor', 'editVendorProperties', 'viewVendorsAndUsers' ] },
        { path: '/admin/pricing', requiredPermissions: [ 'isAnyFullAdmin', 'isLimitedAdmin' ] },
        { path: '/admin/hedges', requiredPermissions: [ 'isAnyFullAdmin', 'isLimitedAdmin' ] },
        { path: '/admin/vendors', requiredPermissions: [ 'deleteVendor', 'editVendorProperties' ] },
        { path: '/control-panel/price-sheet', requiredPermissions: [ 'viewPriceSheet' ] },
        { path: '/crm/tasks', requiredPermissions: [ 'viewTasks' ] },
        { path: '/crm/logs', requiredPermissions: [ 'createLogs' ] },
        { path: '/crm/map', requiredPermissions: [ 'viewMap' ] },
    ];

    constructor(private router: Router,
                private authManager: AuthManagerService)
    {
    }

    ngOnDestroy(): void
    {
        /* Implemented to support untilDestroyed */
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        // if (!this.checkUserPermission(state)) { return false; }
        if (this.authManager.performingAutoLogin) { this.authManager.redirectUrl = state.url; }
        return this.authManager.isUserLoggedInObservable
            .pipe(take(1),
                untilDestroyed(this),
                map(loggedIn =>
                {
                    if ((state.url === '/twoFactor' && this.authManager.userHasTwoFactorToken)) return true;

                    if (!loggedIn)
                    {
                        this.router.navigate([ '/welcome' ]);
                        return false;
                    }

                    const user = this.authManager.currentUser;
                    if (!user.hasAcceptedTermsOfUse && environment.deploymentName !== 'Cat Daddies')
                    {
                        this.router.navigate([ '/auth/termsOfUse' ]);
                        return false;
                    }
                    this.restrictedPaths.forEach(pathInfo =>
                    {
                        // User must have at least one of the permissions listed
                        if (state.url === pathInfo.path && !pathInfo.requiredPermissions.some(p => user[p]))
                        {
                            this.authManager.logout();
                            return false;
                        }
                    });

                    return true;
                }));
        // return Observable.create(observer => {
        //     this.authManager.isUserLoggedInObservable
        //         .pipe(take(1),
        //             untilDestroyed(this),
        //             finalize(() => observer.complet()))
        //         .subscribe(loggedIn =>
        //     {
        //         if (loggedIn || (state.url === '/twoFactor' && this.authManager.userHasTwoFactorToken))
        //         {
        //             observer.next(true);
        //         }
        //         else
        //         {
        //             this.router.navigate(['/welcome']);
        //             observer.next(false);
        //         }
        //     });
        // });
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
        Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return this.canActivate(route, state);
    }

    // private checkUserPermission(state: RouterStateSnapshot)
    // {
    //     if (state.url === '/users' &&
    //         !this.loginAndUserManagerService.performingAutoLogin &&
    //         !(this.loginAndUserManagerService.currentUser.canEditUsers ||
    //         this.loginAndUserManagerService.currentUser.canEditUserFields ||
    //         this.loginAndUserManagerService.currentUser.canEditUserPrices))
    //     {
    //         this.loginAndUserManagerService.signOut();
    //         return false;
    //     }
    //
    //     return true;
    // }
}
