<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<p-button *ngIf="vendorId != null" severity="btn-success" (click)="closeView()">Close</p-button>

<div *ngIf="log != null && log.length === 0 && !busy" class="no-data">
    There are no log entries.
</div>

<div *ngIf="log != null && log.length > 0" class="desktop-table-container">
    <div class="inner-table-container">
        <p-table [value]="log" [columns]="columns" [sortField]="'id'" [sortOrder]="-1"
                 [scrollable]="true" [virtualScroll]="true" scrollHeight="flex" [virtualScrollItemSize]="deviceManager.isMobile ? 185 : 73"
                 responsiveLayout="stack" [tableStyleClass]="'stacked'"
                 selectionMode="single" [(selection)]="selectedLog" (onRowSelect)="onLogSelected($event.data)" (onRowUnselect)="onLogSelected(null)">
            <ng-template pTemplate="header">
                <!-- Column Titles -->
                <tr>
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" [pSortableColumnDisabled]="col.sortDisabled || false" [style.width]="col.width">
                        <div>
                            {{col.header}}
                            <p-sortIcon *ngIf="!(col.sortDisabled || false)" [field]="col.field"></p-sortIcon>
                            <p-columnFilter *ngIf="!(col.filterDisabled || false)" type="text" [field]="col.field" display="menu"
                                            [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"
                                            [matchMode]="'contains'">
                            </p-columnFilter>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-logEntry let-index="rowIndex">
                <tr [pSelectableRow]="logEntry" [class]="index % 2 === 0 ? 'even' : 'odd'">
                    <td *ngFor="let col of columns" [ngSwitch]="col.field" [style.width]="col.width" [style]="col.style">
                        <div class="p-column-title">{{col.header}}</div>
                        <div *ngSwitchCase="'title'" class="description">{{logEntry[col.field]}}</div>
                        <div *ngSwitchCase="'description'" class="description">{{logEntry[col.field]}}</div>
                        <div *ngSwitchCase="'createdOn'">
                            <div *ngIf="deviceManager.isMobile">
                                <span>{{logEntry.createdOn | date:'MM/dd/yy'}}</span>
                                <p-button icon="fas fa-trash" [text]="true" size="large" title="Delete Log Entry" (click)="onDeleteLogEntry(logEntry)"></p-button>
                            </div>
                            <span *ngIf="!deviceManager.isMobile">{{logEntry.createdOn | date:'MM/dd/yy'}}</span>
                        </div>
                        <div *ngSwitchCase="'deleteButton'" style="margin-right: 10px;">
                            <p-button icon="fas fa-trash" [text]="true" size="large" title="Delete Log Entry" (click)="onDeleteLogEntry(logEntry)"></p-button>
                        </div>
                        <div *ngSwitchDefault>{{logEntry[col.field]}}</div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div *ngIf="selectedLog != null" class="selected-log">
        <div><span class="label">Date:</span>{{selectedLog.createdOn | date:'MM/dd/yy'}}</div>
        <div><span class="label">Log #:</span>{{selectedLog.id}}</div>
        <div><span class="label">Buyer:</span>{{selectedLog.buyerName}}</div>
        <div><span class="label">Title:</span>{{selectedLog.title}}</div>
        <div><span class="label">Vendor:</span>{{selectedLog.vendorName}}</div>
        <div><span class="label">Description:</span>{{selectedLog.description}}</div>
        <ng-container *ngIf="selectedLog.contactType !== UserLogContactType.Unknown">
            <div><span class="label">Contact Type:</span>{{UserLog.contactTypeString(selectedLog.contactType)}}</div>
            <div><span class="label">Follow-up Timeframe:</span>{{selectedLog.followUpTimeframe}}</div>
        </ng-container>
    </div>
</div>

<ls-waiting-indicator *ngIf="busy || error" [error]="error" [@fadeInOut] (okClick)="onErrorDismissed()"></ls-waiting-indicator>
