export class Subsidiary
{
    public abbreviatedName: string;
    public address: string;
    public city: string;
    public displayName: string;
    public id = 0;
    public latitude?: number;
    public legalName: string;
    public longitude?: number;
    public name: string;
    public state: string;
    public zip: string;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
        }
    }

    get cityStateZip(): string
    {
        let cityStateZip = this.city || '';
        cityStateZip += (cityStateZip.length === 0 ? '' : ', ') + (this.state ? this.state : '');
        cityStateZip += (cityStateZip.length === 0 ? '' : ' ') + (this.zip ? this.zip : '');
        return cityStateZip;
    }

    get fullAddress(): string { return `${this.address} ${this.cityStateZip}`; }

    clearSubsidiary(): void
    {
        this.id = 0;
        this.name = null;
    }

    updateSubsidiary(subsidiary: Subsidiary): void
    {
        Object.assign(this, subsidiary);
    }
}
