<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="container" [style.background]="backgroundColor">
    <div *ngIf="!showError" class="content" [style.color]="color">
        <div id="progress-spinner-wrapper">
            <span class="pi pi-spinner pi-spin" [style.font-size]="iconSize"></span>
            <div class="progress" *ngIf="percentComplete >= 0">{{percentComplete}}%</div>
        </div>

        <div *ngIf="text" class="text">{{ text }}</div>
    </div>

    <div *ngIf="showError" class="error-content" [ngStyle]="{'color': color, 'max-width': '400px'}">
        <div class="text" [ngStyle]="{'font-size': fontSize}" [innerHTML]="error"></div>
        <div class="buttons">
            <p-button label="OK" (click)="onOkClick()"></p-button>
        </div>
    </div>
</div>
