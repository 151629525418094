import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, timeout } from 'rxjs/operators';

import { Utils } from '../utils.service';

import { ApiService, HttpMethod } from '../api.service';
import { PurchaseOrder } from './purchase-order.model';
import { PurchaseOrdersList } from './purchase-orders-list.model';
import { PurchaseOrderTransaction } from './purchase-order-transaction';
import { ExportedPurchaseOrderModel } from './exported-purchase-order.model';
import { SasTokenModel } from '../../shared-api/images/sas-token.model';
import { AssignedImageResult } from '../converter/assigned-image-result.model';

@Injectable({
    providedIn: 'root'
})
export class PurchaseOrderApiService extends ApiService
{
    private _uploadingTransactions = false;
    get uploadingTransactions(): boolean { return this._uploadingTransactions; }

    constructor(protected http: HttpClient)
    {
        super(http);
    }

    //region Read
    getPurchaseOrder(id: number): Observable<PurchaseOrder>
    {
        return this.apiRequest<any>(this.getPurchaseOrderUrl(id), HttpMethod.Get)
            .pipe(map(json => new PurchaseOrder(json)));
    }

    outputPurchaseOrderAsWordDoc(id: number): Observable<any>
    {
        return this.apiGetRequestForWordDocument(this.outputPurchaseOrderAsWordDocUrl(id));
    }

    getAll(pageIndex: number, pageSize: number, sortField: string, sortOrder: number): Observable<PurchaseOrdersList>
    {
        const sortOrderString = sortOrder === 1 ? 'asc' : 'desc';
        return this.apiRequest<any>(this.getAllPurchaseOrdersUrl(pageIndex, pageSize, sortField, sortOrderString), HttpMethod.Get)
            .pipe(map(json => new PurchaseOrdersList(json)));
    }

    getAllVendorPOs(pageIndex: number, pageSize: number, sortField: string, sortOrder: number): Observable<PurchaseOrdersList>
    {
        const sortOrderString = sortOrder === 1 ? 'asc' : 'desc';
        return this.apiRequest<any>(this.getAllVendorPurchaseOrdersUrl(pageIndex, pageSize, sortField, sortOrderString), HttpMethod.Get)
            .pipe(map(json => new PurchaseOrdersList(json)));
    }

    getInRange(startIndex: number = 0, numItems: number = 0, sortField: string = null, sortOrder: number = 1):
        Observable<PurchaseOrdersList>
    {
        const sortOrderString = sortOrder === 1 ? 'asc' : 'desc';
        return this.apiRequest<any>(this.getPurchaseOrdersInRangeUrl(startIndex, numItems, sortField, sortOrderString), HttpMethod.Get)
            .pipe(map(json => new PurchaseOrdersList(json)));
    }

    searchPurchaseOrdersByDate(startDate: Date, endDate: Date, excludeExported: boolean, searchingCOGS: boolean,
                               excludeUnlocked: boolean):
        Observable<PurchaseOrdersList>
    {
        return this.apiRequest<any>(this.searchPurchaseOrdersByDateUrl(startDate, endDate, excludeExported, searchingCOGS,
                excludeUnlocked),
            HttpMethod.Get).pipe(map(json => new PurchaseOrdersList(json)));
    }

    searchCashPurchaseOrdersByDate(startDate: Date, endDate: Date): Observable<PurchaseOrdersList>
    {
        return this.apiRequest<any>(this.searchCashPurchaseOrdersByDateUrl(startDate, endDate),
            HttpMethod.Get).pipe(map(json => new PurchaseOrdersList(json)));
    }
    //endregion

    //region Create/Update/Delete
    createPO(vendorId: number): Observable<PurchaseOrder>
    {
        const body = { vendorId };
        return this.apiRequest<any>(this.createPoUrl, HttpMethod.Post, JSON.stringify(body))
            .pipe(map((response: any) => new PurchaseOrder(response)));
    }

    deletePO(id: number): Observable<boolean>
    {
        return this.apiRequest<any>(this.deletePoUrl(id), HttpMethod.Delete).pipe(map(() => true));
    }

    changeVendor(purchaseOrderId: number, vendorId: number): Observable<PurchaseOrder>
    {
        const body = { id: purchaseOrderId, vendorId };
        return this.apiRequest<any>(this.changeVendorUrl, HttpMethod.Put, JSON.stringify(body))
            .pipe(map((response: any) => new PurchaseOrder(response)));
    }

    setPurchaseDate(id: number, date: Date): Observable<PurchaseOrder>
    {
        const body = { id, purchaseDate: date };
        return this.apiRequest<any>(this.setPurchaseDateUrl, HttpMethod.Put, JSON.stringify(body))
            .pipe(map((response: any) => new PurchaseOrder(response)));
    }

    setSignaturePageAndLock(id: number, url: string): Observable<PurchaseOrder>
    {
        const body = { id, url };
        return this.apiRequest<any>(this.setSignaturePageUrl, HttpMethod.Put, JSON.stringify(body))
            .pipe(map((response: any) => new PurchaseOrder(response)));
    }

    clearExtraPages(id: number): Observable<boolean>
    {
        return this.apiRequest<any>(this.clearExtraPagesUrl(id), HttpMethod.Put).pipe(map(() => true));
    }

    setExtraPages(id: number, url: string): Observable<boolean>
    {
        const body = { id, url };
        return this.apiRequest<any>(this.setExtraPagesUrl, HttpMethod.Put, JSON.stringify(body)).pipe(map(() => true));
    }

    updatePaymentType(id: number, paymentType: number): Observable<boolean>
    {
        const body = { id, paymentType };
        return this.apiRequest<any>(this.updatePaymentTypeUrl, HttpMethod.Put, JSON.stringify(body)).pipe(map(() => true));
    }

    updatePrices(id: number): Observable<PurchaseOrder>
    {
        return this.apiRequest<any>(this.updatePricesUrl(id), HttpMethod.Put)
            .pipe(map((response: any) => new PurchaseOrder(response)));
    }

    lock(id: number): Observable<PurchaseOrder>
    {
        return this.apiRequest<any>(this.lockUrl(id), HttpMethod.Put).pipe(map((response: any) => new PurchaseOrder(response)));
    }

    unlock(id: number): Observable<void>
    {
        return this.apiRequest<any>(this.unlockUrl(id), HttpMethod.Put);
    }

    getSasTokenUrl(filename: string): Observable<SasTokenModel>
    {
        return this.apiRequest<SasTokenModel>(this.getSasTokenUrlUrl(filename), HttpMethod.Get)
            .pipe(map(response => new SasTokenModel(response)));
    }

    emailPdf(id: number, base64Pdf: string): Observable<any>
    {
        const body = {
            id,
            base64Pdf
        };
        return this.apiRequest<any>(this.emailPdfUrl, HttpMethod.Post, JSON.stringify(body));
    }

    uploadTransactions(transactions: PurchaseOrderTransaction[]): Observable<number[]>
    {
        if (this._uploadingTransactions) throwError('Transactions already being uploaded');

        const body = {
            purchaseOrderId: transactions[0].purchaseOrderId,
            items: transactions.map(item => ({
                action: item.type,
                lineItemType: item.partType,
                itemId: item.itemId,
                id: item.lineItemId,
                pricePerItem: item.pricePerItem,
                quantity: item.quantity,
                categoryHasCustomPrices: item.categoryHasCustomPrices,
                levelPercentage: item.levelPercentage,
            })),
        };

        return this.apiRequest<any>(this.uploadLineItemsUrl, HttpMethod.Post, JSON.stringify(body), 5);
    }

    apiGetRequestForWordDocument(url: string): Observable<any>
    {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.http.get(url, { headers, responseType: 'arraybuffer' }).pipe(timeout(15000));
    }

    addImage(id: number, url: string): Observable<AssignedImageResult>
    {
        const info = { id, url };
        return this.apiRequest(this.addImageUrl, HttpMethod.Put, JSON.stringify(info));
    }

    removeImage(id: number, url: string): Observable<any>
    {
        const info = { id, url };
        return this.apiRequest(this.removeImageUrl, HttpMethod.Put, JSON.stringify(info));
    }
    //endregion

    //region Export
    exportPurchaseOrders(purchaseOrderIds: number[], generateCOGS: boolean, nonFerrousOnly: boolean): Observable<ExportedPurchaseOrderModel>
    {
        const body = {
            purchaseOrderIds,
            generateCOGS,
            nonFerrousOnly
        };
        return this.apiRequest<any>(this.exportPurchaseOrdersUrl, HttpMethod.Post, JSON.stringify(body), 120);
    }

    exportPurchaseOrdersWithPGMData(purchaseOrderIds: number[]): Observable<ExportedPurchaseOrderModel>
    {
        const body = {
            purchaseOrderIds
        };
        return this.apiRequest<any>(this.exportPurchaseOrdersWithPGMDataUrl, HttpMethod.Post, JSON.stringify(body), 120);
    }

    exportExtraPagesZip(purchaseOrderIds: number[]): Observable<ExportedPurchaseOrderModel>
    {
        return this.apiRequest<any>(this.exportExtraPagesZipUrl(purchaseOrderIds), HttpMethod.Get);
    }

    // exportAllPurchaseOrders(purchaseOrderSearchByDate: PurchaseOrderPerformSearchByDateModel): Observable<ExportedPurchaseOrderModel>
    // {
    //     return this.apiService.apiRequest(this.apiUrlService.purchaseOrdersExportAllUrl(purchaseOrderSearchByDate), HttpMethod.Get)
    //         .pipe(map(json => new ExportedPurchaseOrderModel(json)));
    // }
    //endregion

    // =============================================================================================================================
    // URLs
    // =============================================================================================================================
    /* tslint:disable:member-ordering */
    private get baseUrl(): string { return this.baseApiUrl + 'purchaseOrders'; }
    private get createPoUrl(): string { return `${this.baseUrl}/create`; }
    private deletePoUrl(id): string { return `${this.baseUrl}/${id}/delete`; }
    private get changeVendorUrl(): string { return `${this.baseUrl}/changeVendor`; }
    private get setPurchaseDateUrl(): string { return `${this.baseUrl}/setPurchaseDate`; }
    private get setSignaturePageUrl(): string { return `${this.baseUrl}/setSignaturePage`; }
    private clearExtraPagesUrl(id: number): string { return `${this.baseUrl}/${id}/clearExtraPages`; }
    private get setExtraPagesUrl(): string { return `${this.baseUrl}/setExtraPages`; }
    private get updatePaymentTypeUrl(): string { return `${this.baseUrl}/updatePaymentType`; }
    private updatePricesUrl(id: number): string { return `${this.baseUrl}/${id}/updatePrices`; }
    private lockUrl(id: number): string { return `${this.baseUrl}/${id}/lock`; }
    private unlockUrl(id: number): string { return `${this.baseUrl}/${id}/unlock`; }
    private get addImageUrl(): string { return `${this.baseUrl}/addImage`; }
    private get removeImageUrl(): string { return `${this.baseUrl}/removeImage`; }
    private get uploadLineItemsUrl(): string { return `${this.baseUrl}/lineItems`; }
    private get emailPdfUrl(): string { return `${this.baseUrl}/emailPdf`; }
    private getPurchaseOrderUrl(id: number): string { return `${this.baseUrl}/${id}`; }
    private outputPurchaseOrderAsWordDocUrl(id: number): string { return `${this.baseUrl}/createWordDoc/${id}`; }
    private getAllPurchaseOrdersUrl(pageIndex: number, pageSize: number, sortField: string, sortOrder: string): string
    {
        return `${this.baseUrl}/all?pageIndex=${pageIndex}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}`;
    }
    private getAllVendorPurchaseOrdersUrl(pageIndex: number, pageSize: number, sortField: string, sortOrder: string): string
    {
        return `${this.baseUrl}/allVendor?pageIndex=${pageIndex}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}`;
    }
    private getPurchaseOrdersInRangeUrl(startIndex: number, numItems: number, sortField: string, sortOrder: string): string
    {
        return `${this.baseUrl}/range?startIndex=${startIndex}&numItems=${numItems}&sortField=${sortField}&sortOrder=${sortOrder}`;
    }
    private searchPurchaseOrdersByDateUrl(startDate: Date, endDate: Date, excludeExported: boolean, searchingCOGS: boolean,
                                          excludeUnlocked: boolean): string
    {
        return `${this.baseUrl}/byDate?startDate=${Utils.getDateApiString(startDate)}&endDate=${Utils.getDateApiString(endDate)}&excludeExported=${excludeExported}&searchingCOGS=${searchingCOGS}&&excludeUnlocked=${excludeUnlocked}`;
    }
    private searchCashPurchaseOrdersByDateUrl(startDate: Date, endDate: Date): string
    {
        return `${this.baseUrl}/cashByDate?startDate=${Utils.getDateApiString(startDate)}&endDate=${Utils.getDateApiString(endDate)}`;
    }
    private get exportPurchaseOrdersUrl(): string { return `${this.baseUrl}/export`; }
    private get exportPurchaseOrdersWithPGMDataUrl(): string { return `${this.baseUrl}/exportPGM`; }
    private exportExtraPagesZipUrl(purchaseOrderIds: number[]): string
    {
        const idsParam = purchaseOrderIds.reduce((list, id) => `${list}${list.length === 0 ? '' : '&'}id=${id}`, '');
        return `${this.baseUrl}/exportExtraPagesZip?${idsParam}`;
    }
    private getSasTokenUrlUrl(filename: string): string { return `${this.baseUrl}/sas/${filename}`; }
}
