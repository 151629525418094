import { Utils } from '../utils.service';
import { UserNotificationMethod } from '../user/user.model';

// Note: Vendor.VendorLastInteractionType uses these types
export enum UserLogContactType
{
    Unknown,
    PhoneCall,
    SiteVisit,
    Text,
    Email,
    Other
}

export class UserLog
{

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
            if (json.createdOn != null) this.createdOn = Utils.convertApiDateStringToDate(json.createdOn);
        }
    }

    static userLogContactTypeOptions = [
        { label: UserLog.contactTypeString(UserLogContactType.PhoneCall), value: UserLogContactType.PhoneCall },
        { label: UserLog.contactTypeString(UserLogContactType.SiteVisit), value: UserLogContactType.SiteVisit },
        { label: UserLog.contactTypeString(UserLogContactType.Text), value: UserLogContactType.Text },
        { label: UserLog.contactTypeString(UserLogContactType.Email), value: UserLogContactType.Email },
        { label: UserLog.contactTypeString(UserLogContactType.Other), value: UserLogContactType.Other },
    ];

    public id = 0;
    public buyerName: string;
    public contactType = UserLogContactType.Unknown;
    public createdOn: Date;
    public description: string;
    public followUpTimeframe = 1;
    public title: string;
    public vendorId: number;
    public vendorName: string;

    public static contactTypeString(contactType: UserLogContactType): string
    {
        switch (contactType)
        {
            case UserLogContactType.PhoneCall: return 'Phone Call';
            case UserLogContactType.SiteVisit: return 'Site Visit';
            case UserLogContactType.Text: return 'Text';
            case UserLogContactType.Email: return 'Email';
            case UserLogContactType.Other: return 'Other';
            default: return 'None';
        }
    }
}
