import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ThemeManager } from '../../managers/theme.manager';

@Component({
    selector: 'ls-waiting-indicator',
    templateUrl: './waiting-indicator.component.html',
    styleUrls: ['./waiting-indicator.component.less'],
})
export class WaitingIndicatorComponent
{
    @HostBinding('class') get classes(): string { return ThemeManager.theme; }

    static animations = [
        trigger('fadeInOut', [
            transition('void => *', [
                style({opacity: 1}),
            ]),
            transition('* => void', [
                animate(500, style({opacity: 0}))
            ])
        ])
    ];

    static animations_no_fade = [
        trigger('fadeInOut', [
            transition('void => *', [
                style({opacity: 1}),
            ]),
            transition('* => void', [
                animate(0, style({opacity: 0}))
            ])
        ])
    ];

    @Input() backgroundColor = 'transparent';
    @Input() color: string;
    @Input() fontSize = '16px';
    @Input() iconSize = '80px';
    @Input() text: string;
    @Input() percentComplete = -1;

    private _error: string = null;
    @Input() set error(value: string)
    {
        this._error = value;
        this.showError = (this._error && this._error.length > 0);
    }

    get error(): string { return this._error; }

    @Output() okClick = new EventEmitter();

    showError = false;

    constructor() { }

    onOkClick(): void
    {
        this.okClick.emit();
    }
}
