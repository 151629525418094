import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, HttpMethod } from '../api.service';
import { Subsidiary } from './subsidiary.model';

@Injectable({
    providedIn: 'root'
})
export class SubsidiaryApiService extends ApiService
{
    constructor(protected http: HttpClient)
    {
        super(http);
    }

    getAllSubsidiaries(): Observable<Subsidiary []>
    {
        return this.apiRequest<any>(this.getAllSubsidiariesUrl, HttpMethod.Get)
            .pipe(map(subsidiaries => subsidiaries.map(json => new Subsidiary(json))));
    }


    // =============================================================================================================================
    // URLs
    // =============================================================================================================================
    /* tslint:disable:member-ordering */
    private get baseUrl(): string { return this.baseApiUrl + 'subsidiaries'; }
    private get getAllSubsidiariesUrl(): string { return `${this.baseUrl}`; }
}
