import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { CoreModule } from '../core/core.module';

import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { SlideMenuModule } from 'primeng/slidemenu';
import { TableModule } from 'primeng/table';

import { CaliforniaCompliancePageComponent } from './purchase-order/compliance-pages/california-compliance-page/california-compliance-page.component';
import { CaptureSignatureComponent } from './purchase-order/capture-signature/capture-signature.component';
import { FormInputFieldErrorComponent } from './form-input-field-error/form-input-field-error.component';
import { HeaderComponent } from './header/header.component';
import { IllinoisCompliancePageComponent } from './purchase-order/compliance-pages/illinois-compliance-page/illinois-compliance-page.component';
import { LocationToolbarComponent } from './header/location-toolbar/location-toolbar.component';
import { PurchaseOrderComponent } from './purchase-order/purchase-order/purchase-order.component';
import { PurchaseOrderPDFComponent } from './purchase-order/purchase-order-pdf/purchase-order-pdf.component';
import { PurchaseOrderToolbarComponent } from './header/purchase-order-toolbar/purchase-order-toolbar.component';
import { TableHeaderComponent } from './table-header/table-header.component';
import { WaitingIndicatorComponent } from './waiting-indicator/waiting-indicator.component';
import { TexasCompliancePageComponent } from './purchase-order/compliance-pages/texas-compliance-page/texas-compliance-page.component';
import { WestVirginiaCompliancePageComponent } from './purchase-order/compliance-pages/west-virginia-compliance-page/west-virginia-compliance-page.component';

// tslint:disable-next-line:max-line-length
import { DefaultCompliancePageComponent } from './purchase-order/compliance-pages/default-compliance-page/default-compliance-page.component';
import { PdfWriter } from './pdf-utils/pdf-writer';
import { InputMaskModule } from 'primeng/inputmask';
import { LogListComponent } from './user-logs/log-list/log-list.component';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { SubsidiaryToolbarComponent } from './header/subsidiary-toolbar/subsidiary-toolbar.component';
import { TieredMenuModule } from 'primeng/tieredmenu';

@NgModule({
    declarations: [
        CaliforniaCompliancePageComponent,
        CaptureSignatureComponent,
        FormInputFieldErrorComponent,
        HeaderComponent,
        IllinoisCompliancePageComponent,
        LocationToolbarComponent,
        LogListComponent,
        PurchaseOrderComponent,
        PurchaseOrderPDFComponent,
        PurchaseOrderToolbarComponent,
        TableHeaderComponent,
        WaitingIndicatorComponent,
        TexasCompliancePageComponent,
        DefaultCompliancePageComponent,
        WestVirginiaCompliancePageComponent,
        SubsidiaryToolbarComponent,
    ],
    imports: [
        CommonModule,
        CoreModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule,
        RouterModule,

        CalendarModule,
        CheckboxModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        DropdownModule,
        FileUploadModule,
        InputMaskModule,
        InputNumberModule,
        InputTextModule,
        MenubarModule,
        MenuModule,
        MessageModule,
        MessagesModule,
        SlideMenuModule,
        TieredMenuModule,
        TableModule,
        AngularSignaturePadModule,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,

        CaptureSignatureComponent,
        SubsidiaryToolbarComponent,
        AngularSignaturePadModule,

        CalendarModule,
        CheckboxModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        DropdownModule,
        FileUploadModule,
        InputMaskModule,
        InputNumberModule,
        InputTextModule,
        MenuModule,
        MessageModule,
        MessagesModule,
        SlideMenuModule,
        TieredMenuModule,
        TableModule,

        FormInputFieldErrorComponent,
        HeaderComponent,
        LogListComponent,
        PurchaseOrderComponent,
        TableHeaderComponent,
        WaitingIndicatorComponent
    ],
    providers: [ CurrencyPipe, DatePipe, PdfWriter ]
})
export class CoreUiModule {}
