import * as moment from 'moment';
import { Utils } from '../utils.service';
import { MetalPrices } from '../pricing/metal-prices.model';
import { ProcessorTerms } from '../pricing/processor-terms.model';
import { Hedge } from '../pricing/hedge.model';
import { User, UserNotificationMethod } from '../user/user.model';
import { Contact } from './contact.model';
import { UserLog, UserLogContactType } from '../user-log/user-log.model';

export enum VendorLicenseState
{
    Valid,
    Expired,
    Missing,
}

export enum VendorActivityStatus
{
    Active,
    Inactive,
    NotSelling,
    LostWarning,
    Lost,
    Prospect,
    Unknown,
    ActiveNew,
}

// Note: These should match up with UserLog.UserLogContactType (with Unknown -> None and adding Purchase)
export enum VendorLastInteractionType
{
    None,
    PhoneCall,
    SiteVisit,
    Text,
    Email,
    Other,
    Purchase,
    PGMSystem
}

export enum VendorVolumeDuration
{
    Weekly,
    Monthly,
    Quarterly,
    Annually
}

export enum VendorBusinessActivity
{
    SalvageYardOrDismantler,
    CoreRecycler,
    AutoRepair,
    ScrapDealerOrMetalRecycler,
    MufflerShop,
    Other
}

export enum VendorProspectSource
{
    ColdCall,
    ConferenceARA,
    ConferenceISRI,
    ConferencePRURG,
    ConferenceOther,
    CustomerContactedUs,
    Referral,
    SocialMedia,
    WebsiteInquiry,
    MarketingStop,
    Other,
}

export class Vendor
{

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
            if (json.licenseExpiration != null) this.licenseExpiration = Utils.convertApiUtcDateStringToDate(json.licenseExpiration);
            if (json.licenseLastUpdated != null) this.licenseLastUpdated = Utils.convertApiDateStringToDate(json.licenseLastUpdated);
            if (json.lastInteractionDate != null) this.lastInteractionDate = Utils.convertApiDateStringToDate(json.lastInteractionDate);
            if (json.nextInteractionDate != null) this.nextInteractionDate = Utils.convertApiDateStringToDate(json.nextInteractionDate);
            if (json.metalPrices != null) this.metalPrices = json.metalPrices.map(item => new MetalPrices(item));
            if (json.parentVendor != null) this.parentVendor = new Vendor(json.parentVendor);
            if (json.processorTerms != null) this.processorTerms = json.processorTerms.map(item => new ProcessorTerms(item));
            if (json.hedges != null) this.hedges = json.hedges.map(item => new Hedge(item));
            if (json.buyers != null) this.buyers = json.buyers.map(item => new User(item));
            if (json.contacts != null) this.contacts = json.contacts.map(item => new Contact(item));
            if (this.licenseImageUrls == null) this.licenseImageUrls = [];
        }
    }

    get cityStateZip(): string
    {
        let cityStateZip = this.city || '';
        cityStateZip += (cityStateZip.length === 0 ? '' : ', ') + (this.state ? this.state : '');
        cityStateZip += (cityStateZip.length === 0 ? '' : ' ') + (this.zip ? this.zip : '');
        return cityStateZip;
    }

    // Simply for search
    get fullAddress(): string { return `${this.address ? (this.address + ', ') : ''} ${this.cityStateZip}`; }

    get fullAddressHTML(): string
    {
        return '<div class="address">' +
            `<div class="street-address">${this.address ? (this.address + ', &nbsp;') : ''}</div>` +
            `<div class="city-state-zip">${this.cityStateZip}</div>` +
            '</div>';
    }

    get isLicenseExpirationRequired(): boolean
    {
        return Vendor.isLicenseExpirationRequiredForType(this.licenseType);
    }

    get isLicenseExpirationValid(): boolean {
        if (this.licenseExpiration == null) return !this.isLicenseExpirationRequired;

        const m = moment(this.licenseExpiration);
        const tomorrow = moment().startOf('day').add(1, 'day');
        return m.isValid() && m >= tomorrow && m.year() <= 2050;
    }

    get licenseState(): VendorLicenseState
    {
        return this.isLicenseExpirationValid ? VendorLicenseState.Valid :
            this.licenseExpiration == null ? VendorLicenseState.Missing : VendorLicenseState.Expired;
    }

    get hasCompleteData(): boolean {
        return (this.name != null && this.name.length > 0) &&
            (this.address != null && this.address.length > 0) &&
            (this.city != null && this.city.length > 0) &&
            (this.state != null && this.state.length > 0) &&
            (this.zip != null && this.zip.length > 0) &&
            this.hasCompleteLicenseData;
    }

    get hasCompleteLicenseData(): boolean {
        return (this.licenseNumber != null && this.licenseNumber.length > 0 && this.licenseImageUrls.length > 0) &&
            this.isLicenseExpirationValid;
    }
    public static ASSAY_VENDOR_LOCATION_ID = -2;
    public static LICENSE_TYPE_SELLERS_PERMIT = 'Seller\'s Permit';
    public static LICENSE_TYPE_SALES_AND_USE_PERMIT = 'Sales and Use Permit';
    // tslint:disable-next-line:max-line-length
    public static CANNOT_UPDATE_VENDOR_MESSAGE = 'The selected vendor does not have all required information, and you do not have permission to update the vendor. Please contact the administrator for assistance.';

    public activityStatus = VendorActivityStatus.Unknown;
    public address: string;
    public alternatePhone: string;
    public businessActivity: VendorBusinessActivity;
    public buyers: User[];
    public city: string;
    public contactTitle: string;
    public contacts: Contact[] = [];
    public contractNotificationMethod = UserNotificationMethod.None;
    public email: string;
    public fax: string;
    public hedges: Hedge[];
    public id = 0;
    public isAssayVendor: boolean;
    public lastBuyerName: string;
    public lastInteractionDate: Date;
    public lastInteractionType: VendorLastInteractionType;
    public latitude?: number;
    public licenseExpiration: Date;
    public licenseImageUrls: string[] = [];
    public licenseLastUpdated: Date;
    public licenseNumber: string;
    public licenseType: string;
    public locationId: number;
    public locationName: string;
    public logoImageUrl: string;
    public longitude?: number;
    public mainPhone: string;
    public memo1: string;
    public memo2: string;
    public metalPrices: MetalPrices[];
    public name: string;
    public nextInteractionDate: Date;
    public numberOfLogs: number;
    public numberOfPurchaseOrders: number;
    public parentVendor: Vendor;
    public parentVendorId: number;
    public parentVendorName: string;
    public pricingNotificationMethod = UserNotificationMethod.None;
    public primaryContact: string;
    public processorTerms: ProcessorTerms[];
    public prospectSource: VendorProspectSource;
    public purchaseOrderIds: number[] = [];
    public secondaryContact: string;
    public sellsTo: string;
    public state: string;
    public statusUpdateNotificationMethod = UserNotificationMethod.None;
    public volume: number;
    public volumeDuration: VendorVolumeDuration;
    public zip: string;

    // tslint:disable-next-line:member-ordering
    static isLicenseExpirationRequiredForType(licenseType: string): boolean
    {
        return licenseType !== Vendor.LICENSE_TYPE_SALES_AND_USE_PERMIT &&
            licenseType !== Vendor.LICENSE_TYPE_SELLERS_PERMIT;
    }

    static interactionTypeString(vendor: Vendor): string
    {
        switch (vendor.lastInteractionType)
        {
            case VendorLastInteractionType.None: return vendor.numberOfLogs === 0 ? '[NONE]' : 'Log Entry';
            case VendorLastInteractionType.PGMSystem: return 'PGM System';
            case VendorLastInteractionType.Purchase: return 'Purchase';
            default: return UserLog.contactTypeString(vendor.lastInteractionType as unknown as UserLogContactType);
        }
    }

    static activityStatusString(activityStatus: VendorActivityStatus): string
    {
        switch (activityStatus)
        {
            case VendorActivityStatus.ActiveNew: return 'Active New';
            case VendorActivityStatus.Active: return 'Active';
            case VendorActivityStatus.Inactive: return 'Inactive';
            case VendorActivityStatus.NotSelling: return 'No Sale';
            case VendorActivityStatus.Lost: return 'Lost';
            case VendorActivityStatus.LostWarning: return 'Lost Warning';
            case VendorActivityStatus.Prospect: return 'Prospect';
            default: return 'Unknown';
        }
    }

    static activityStatusBackgroundColor(activityStatus: VendorActivityStatus): string
    {
        switch (activityStatus)
        {
            case VendorActivityStatus.ActiveNew: return 'fuchsia';
            case VendorActivityStatus.Active: return 'lawngreen';
            case VendorActivityStatus.Inactive: return 'lightgray';
            case VendorActivityStatus.NotSelling: return 'black';
            case VendorActivityStatus.Lost: return 'red';
            case VendorActivityStatus.LostWarning: return 'yellow';
            case VendorActivityStatus.Prospect: return 'cyan';
            default: return 'lightpink';
        }
    }

    static volumeDurationString(duration: VendorVolumeDuration): string
    {
        switch (duration)
        {
            case VendorVolumeDuration.Weekly: return 'Weekly';
            case VendorVolumeDuration.Monthly: return 'Monthly';
            case VendorVolumeDuration.Quarterly: return 'Quarterly';
            case VendorVolumeDuration.Annually: return 'Annually';
            default: return '';
        }
    }

    static businessActivityString(businessActivity: VendorBusinessActivity): string
    {
        switch (businessActivity)
        {
            case VendorBusinessActivity.SalvageYardOrDismantler: return 'Salvage Yard / Dismantler';
            case VendorBusinessActivity.CoreRecycler: return 'Core Recycler';
            case VendorBusinessActivity.AutoRepair: return 'Auto Repair';
            case VendorBusinessActivity.ScrapDealerOrMetalRecycler: return 'Scrap Dealer / Metal Recycler';
            case VendorBusinessActivity.MufflerShop: return 'Muffler Shop';
            case VendorBusinessActivity.Other: return 'Other';
            default: return '';
        }
    }

    static prospectSourceString(prospectSource: VendorProspectSource): string
    {
        switch (prospectSource)
        {
            case VendorProspectSource.ColdCall: return 'Cold Call';
            case VendorProspectSource.ConferenceARA: return 'ARA';
            case VendorProspectSource.ConferenceISRI: return 'ISRI';
            case VendorProspectSource.ConferencePRURG: return 'PRP/URG';
            case VendorProspectSource.ConferenceOther: return 'Conference, Other';
            case VendorProspectSource.CustomerContactedUs: return 'Customer Contacted Us';
            case VendorProspectSource.Referral: return 'Referral';
            case VendorProspectSource.SocialMedia: return 'Social Media';
            case VendorProspectSource.WebsiteInquiry: return 'Website Inquiry';
            case VendorProspectSource.MarketingStop: return 'Marketing Stop';
            case VendorProspectSource.Other: return 'Other';
            default: return '';
        }
    }

    clear(): void
    {
        this.name = '';
        this.address = '';
        this.city = '';
        this.state = '';
        this.zip = '';
        this.email = '';
    }
}
