import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService, HttpMethod } from '../api.service';
import { map } from 'rxjs/operators';
import { VendorList } from './vendor-list.model';
import { Vendor } from './vendor.model';
import { SasTokenModel } from '../../shared-api/images/sas-token.model';
import { AssignedImageResult } from '../converter/assigned-image-result.model';
import { Contact } from './contact.model';
import { UserNotificationMethod } from '../user/user.model';
import { CalculatePricesResult } from './calculate-prices.model';
import { VendorMapInfo } from './vendor-map-info.model';

@Injectable({
    providedIn: 'root'
})
export class VendorApiService extends ApiService
{
    constructor(protected http: HttpClient)
    {
        super(http);
    }

    getAllVendors(locationId: number, includeAssayVendors: boolean, includePricing: boolean, includeHedges: boolean,
                  includeSubVendors: boolean): Observable<VendorList>
    {
        return this.apiRequest<VendorList>(
            this.getAllVendorsUrl(locationId, includeAssayVendors, includePricing, includeHedges, includeSubVendors),
            HttpMethod.Get, undefined, 60).pipe(map(json => new VendorList(json)));
    }

    getMapInfo(locationId: number): Observable<VendorMapInfo[]>
    {
        return this.apiRequest<any>(this.getVendorMapInfoUrl(locationId), HttpMethod.Get)
            .pipe(map(json => json.map(item => new VendorMapInfo(item))));
    }

    getVendor(vendorId: number): Observable<Vendor>
    {
        return this.apiRequest<Vendor>(this.getVendorUrl(vendorId), HttpMethod.Get).pipe(map(json => new Vendor(json)));
    }

    getVendorWithEffectivePricing(vendorId: number): Observable<Vendor>
    {
        return this.apiRequest<Vendor>(this.getVendorWithEffectivePricingUrl(vendorId), HttpMethod.Get).pipe(map(json => new Vendor(json)));
    }

    getHedgeInfo(locationId: number): Observable<Vendor[]>
    {
        return this.apiRequest<Vendor[]>(this.getHedgeInfoUrl(locationId), HttpMethod.Get)
            .pipe(map(json => json.map(item => new Vendor(item))));
    }

    addVendor(vendor: Vendor, locationId: number): Observable<Vendor>
    {
        const updateObj = Object.assign({...vendor}, { locationId });
        return this.apiRequest(this.addVendorUrl, HttpMethod.Post, JSON.stringify(updateObj))
            .pipe(map(json => new Vendor(json)));
    }

    updateVendor(vendor: Vendor): Observable<Vendor>
    {
        return this.apiRequest(this.updateVendorUrl, HttpMethod.Put, JSON.stringify(vendor))
            .pipe(map(json => new Vendor(json)));
    }

    deleteVendor(vendorId: number): Observable<null>
    {
        return this.apiRequest(this.deleteVendorUrl(vendorId), HttpMethod.Delete);
    }

    addContact(contact: Contact, vendorId: number): Observable<Contact>
    {
        const updateObj = Object.assign({...contact}, { vendorId });
        return this.apiRequest(this.addContactUrl, HttpMethod.Post, JSON.stringify(updateObj))
            .pipe(map(json => new Contact(json)));
    }

    updateContact(contact: Contact): Observable<Contact>
    {
        return this.apiRequest(this.updateContactUrl, HttpMethod.Put, JSON.stringify(contact))
            .pipe(map(json => new Contact(json)));
    }

    deleteContact(contactId: number): Observable<null>
    {
        return this.apiRequest(this.deleteContactUrl(contactId), HttpMethod.Delete);
    }

    getSasTokenUrl(filename: string): Observable<SasTokenModel>
    {
        return this.apiRequest<SasTokenModel>(this.getSasTokenUrlUrl(filename), HttpMethod.Get)
            .pipe(map(response => new SasTokenModel(response)));
    }

    licenseImageUploaded(vendorId: number, url: string): Observable<AssignedImageResult>
    {
        const info = { id: vendorId, url };
        return this.apiRequest(this.licenseImageUploadedUrl, HttpMethod.Put, JSON.stringify(info));
    }

    removeLicenseImage(vendorId: number, url: string): Observable<any>
    {
        const info = { id: vendorId, url };
        return this.apiRequest(this.removeLicenseImageUrl, HttpMethod.Put, JSON.stringify(info));
    }

    logoUploaded(vendorId: number, url: string): Observable<AssignedImageResult>
    {
        const info = { id: vendorId, url };
        return this.apiRequest(this.logoUploadedUrl, HttpMethod.Put, JSON.stringify(info));
    }

    removeLogo(vendorId: number): Observable<any>
    {
        const info = { id: vendorId };
        return this.apiRequest(this.removeLogoUrl, HttpMethod.Put, JSON.stringify(info));
    }

    updatePricingNotification(vendorId: number, method: UserNotificationMethod): Observable<any>
    {
        const info = { id: vendorId, method };
        return this.apiRequest(this.updatePricingNotificationUrl, HttpMethod.Put, JSON.stringify(info));
    }

    updateContractNotification(vendorId: number, method: UserNotificationMethod): Observable<any>
    {
        const info = { id: vendorId, method };
        return this.apiRequest(this.updateContractNotificationUrl, HttpMethod.Put, JSON.stringify(info));
    }

    updateStatusUpdateNotification(vendorId: number, method: UserNotificationMethod): Observable<any>
    {
        const info = { id: vendorId, method };
        return this.apiRequest(this.updateStatusUpdateNotificationUrl, HttpMethod.Put, JSON.stringify(info));
    }

    contactDriversLicenseImageUploaded(contactId: number, url: string): Observable<AssignedImageResult>
    {
        const info = { id: contactId, url };
        return this.apiRequest(this.contactDriversLicenseImageUploadedUrl, HttpMethod.Put, JSON.stringify(info));
    }

    calculatePrices(platinumPercent: number, palladiumPercent: number, rhodiumPercent: number, weight: number):
        Observable<CalculatePricesResult>
    {
        const info = { platinumPercent, palladiumPercent, rhodiumPercent, weight };
        return this.apiRequest(this.calculatePricesUrl, HttpMethod.Put, JSON.stringify(info))
            .pipe(map(response => new CalculatePricesResult(response)));
    }

    // =============================================================================================================================
    // URLs
    // =============================================================================================================================
    /* tslint:disable:member-ordering */
    // tslint:disable:max-line-length
    private get baseUrl(): string { return this.baseApiUrl + 'vendors'; }
    private get baseContactUrl(): string { return this.baseApiUrl + 'vendors/contact'; }
    private getVendorUrl(id: number): string { return `${this.baseUrl}/${id}`; }
    private getVendorWithEffectivePricingUrl(id: number): string { return `${this.baseUrl}/${id}/effectivePricing`; }
    private getAllVendorsUrl(locationId: number, includeAssayVendors: boolean, includePricing: boolean, includeHedges: boolean, includeSubVendors: boolean): string
    { return `${this.baseUrl}/all?locationId=${locationId}&includeAssayVendors=${includeAssayVendors}&includePricing=${includePricing}&includeHedges=${includeHedges}&includeSubVendors=${includeSubVendors}&sortField=name`; }
    private getVendorMapInfoUrl(locationid: number): string { return `${this.baseUrl}/mapInfo?locationId=${locationid}`; }
    private getHedgeInfoUrl(locationid: number): string { return `${this.baseUrl}/hedges?locationId=${locationid}`; }
    private get addVendorUrl(): string { return `${this.baseUrl}`; }
    private get updateVendorUrl(): string { return `${this.baseUrl}`; }
    private deleteVendorUrl(id: number): string { return `${this.baseUrl}/${id}`; }
    private get addContactUrl(): string { return `${this.baseContactUrl}`; }
    private get updateContactUrl(): string { return `${this.baseContactUrl}`; }
    private deleteContactUrl(id: number): string { return `${this.baseContactUrl}/${id}`; }
    private getSasTokenUrlUrl(filename: string): string { return `${this.baseUrl}/sas/${filename}`; }
    private get licenseImageUploadedUrl(): string { return `${this.baseUrl}/licenseImageUploaded`; }
    private get removeLicenseImageUrl(): string { return `${this.baseUrl}/removeLicenseImage`; }
    private get logoUploadedUrl(): string { return `${this.baseUrl}/logoUploaded`; }
    private get removeLogoUrl(): string { return `${this.baseUrl}/removeLogo`; }
    private get updatePricingNotificationUrl(): string { return `${this.baseUrl}/pricingNotification`; }
    private get updateContractNotificationUrl(): string { return `${this.baseUrl}/contractNotification`; }
    private get updateStatusUpdateNotificationUrl(): string { return `${this.baseUrl}/statusUpdateNotification`; }
    private get contactDriversLicenseImageUploadedUrl(): string { return `${this.baseContactUrl}/driversLicenseImageUploaded`; }
    private get calculatePricesUrl(): string { return `${this.baseUrl}/calculatePrices`; }
}
