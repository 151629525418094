import { Component, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder} from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ConfirmationService, FilterService } from 'primeng/api';

import { WaitingIndicatorComponent } from '../../waiting-indicator/waiting-indicator.component';

import { UserLog, UserLogContactType } from '../../../services/user-log/user-log.model';
import { DeviceManagerService } from '../../../core/services/device-manager.service';
import { UserLogManagerService } from '../../../services/user-log/user-log-manager.service';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { AuthManagerService } from '../../../services/auth/auth-manager.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { ThemeManager } from '../../../managers/theme.manager';

@Component({
    selector: 'ls-log-list',
    templateUrl: './log-list.component.html',
    styleUrls: [ './log-list.component.less' ],
    animations: WaitingIndicatorComponent.animations,
})
export class LogListComponent implements OnInit, OnDestroy
{
    @HostBinding('class') get classes(): string { return ThemeManager.theme; }

    static isDisplayed = false;

    @Input() vendorId: number;
    @Output() hide = new EventEmitter();

    _locationId: number;
    @Input() set locationId(value: number) {
        if (this._locationId === value) return;
        this._locationId = value;
        this.loadData();
    }
    get locationId(): number { return this._locationId; }

    busy = false;
    columns: any[];
    error: string;
    log: UserLog[];
    selectedLog: UserLog;
    componentHeight: string;

    protected readonly UserLogContactType = UserLogContactType;
    protected readonly UserLog = UserLog;

    constructor(private elementRef: ElementRef,
                public authManager: AuthManagerService,
                private broadcastService: BroadcastService,
                private confirmationService: ConfirmationService,
                public deviceManager: DeviceManagerService,
                private fb: UntypedFormBuilder,
                private filterService: FilterService,
                private userLogManager: UserLogManagerService) { }

    ngOnInit(): void
    {
        LogListComponent.isDisplayed = true;
        this.setupColumns();
        this.locationId = this.authManager.currentUser.locationId;
        setTimeout(() => {
            this.componentHeight = `${this.elementRef.nativeElement.offsetHeight}px`;
        }, 0);
    }

    ngOnDestroy(): void {
        LogListComponent.isDisplayed = false;
    }

    onLogSelected(userLog: UserLog): void
    {
        this.selectedLog = userLog;
    }

    onErrorDismissed(): void
    {
        this.error = null;
    }

    private loadData(): void
    {
        this.log = [];
        this.selectedLog = null;

        this.busy = true;
        const api = this.vendorId == null ? this.userLogManager.getLogs() : this.userLogManager.getLogsForVendor(this.vendorId);
        api.pipe(finalize(() => this.busy = false )).subscribe(log =>
        {
            this.log = log.sort((a, b) => b.id - a.id);
        }, error =>
        {
            this.error = ErrorHandlerService.handleError(this, error, 'Load Log', false, false);
        });
    }

    private setupColumns(): void
    {
        this.columns = [
            { field: 'createdOn', header: 'Date', width: '8%', filterDisabled: true },
            { field: 'id', header: 'Log #', width: '8%', filterDisabled: true },
            { field: 'buyerName', header: 'Buyer', width: '12%' },
            { field: 'title', header: 'Title', width: '20%', sortDisabled: true },
            { field: 'vendorName', header: 'Vendor', width: '20%' },
            { field: 'description', header: 'Description', width: '30%', style: {'word-break': 'break-all'}, sortDisabled: true  },
        ];

        if (!this.deviceManager.isMobile && this.authManager.currentUser.deletePurchaseOrder)
        {
            this.columns.push({ field: 'deleteButton', header: '', width: '60px', sortDisabled: true, filterDisabled: true });
        }
    }

    logAdded(log: UserLog): void
    {
        this.log.push(log);
        this.log = [...this.log];
    }

    onDeleteLogEntry(logEntry: UserLog): void
    {
        this.confirmationService.confirm({
            message: 'This log will be deleted permanently. Do you wish to continue?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () =>
            {
                this.busy = true;
                this.userLogManager.deleteUserLog(logEntry.id)
                    .pipe(finalize(() => this.busy = false ))
                    .subscribe(() =>
                    {
                        this.log = this.log.filter(l => l.id !== logEntry.id);
                        this.broadcastService.deletedLog.next(logEntry.id);
                    }, error =>
                    {
                        this.error = ErrorHandlerService.handleError(this, error, 'Delete Log', false, false);
                    });
            }
        });
    }

    closeView(): void
    {
        LogListComponent.isDisplayed = false;
        this.hide.emit();
    }
}
