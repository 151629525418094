<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div class="po-pdf">
    <div class="header">{{headerText}}</div>

    <div class="po-info">
        <table style="max-width: 400px; ">
            <tr class="bordered-row"><th *ngFor="let column of columns" [style.width]="column.widthPercent + '%'">{{column.title}}</th></tr>

            <tr class="bordered-row">
                <td [style.width]="columns[0].widthPercent + '%'">{{poManager.current.number}}</td>
                <td [style.width]="columns[1].widthPercent + '%'">{{poManager.current.totalConvertersPrice | currency:'USD':'symbol'}}</td>
            </tr>
        </table>
    </div>

    <div class="buyer-info">
        <div>{{buyerText}}</div>

        <div style="margin-top: 20px">
            <div>{{poManager.current.buyerName}}</div>
            <div>{{poManager.current.location.address}}</div>
            <div>{{poManager.current.location.city}}, {{poManager.current.location.state}}&nbsp;&nbsp;{{poManager.current.location.zip}}</div>
        </div>
    </div>

    <div class="declaration-area">
        <div>{{declaration1}}</div>
        <div>{{declaration2}}</div>
        <div>{{declaration3}}</div>
        <div>{{declaration4}}</div>
    </div>

    <div class="seller-info">
        <div>{{sellerInfoHeaderText}}</div>

        <div style="margin-top: 20px">
            <div>{{poManager.current.vendorName}}</div>
            <div>{{poManager.current.vendor.address}}</div>
            <div>{{poManager.current.vendor.city}}, {{poManager.current.vendor.state}}&nbsp;&nbsp;{{poManager.current.vendor.zip}}</div>
        </div>

        <div class="multi-signature-line tall signature">
            <div class="text">{{removeUnderscores(signatureText)}}</div>
            <div class="signature-auto-expand"><ls-capture-signature #signature></ls-capture-signature></div>
            <div class="field-group">
                <div class="p-inputgroup">
                    <span>{{removeUnderscores(dateText)}}</span>
                    <p-calendar [(ngModel)]="date" [ngModelOptions]="{standalone: true}" [styleClass]="ThemeManager.theme" appendTo="body" inputStyleClass="calendar-input-style"></p-calendar>
                </div>
            </div>
        </div>

        <div class="field-group printed-name">
            <div class="p-inputgroup">
                <span>{{removeUnderscores(printText)}}</span><input type="text" pInputText [(ngModel)]="name" [ngModelOptions]="{standalone: true}" class="name"/>
            </div>
        </div>
    </div>

    <div class="buttons">
        <div class="buttons-left">
            <p-button severity="secondary" (click)="clearSignatures()">Clear Signatures</p-button>
        </div>
        <div class="buttons-right">
            <p-button severity="secondary" (click)="onCancel()">Cancel</p-button>
            <p-button [disabled]="signature.isEmpty || name.length === 0 || date == null" (click)="onSave()">Confirm</p-button>
        </div>
    </div>
</div>
