import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import * as cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';

import { PurchaseOrderManagerService } from '../../../../services/purchase-order/purchase-order-manager.service';
import { PurchaseOrderPDFComponent } from '../../purchase-order-pdf/purchase-order-pdf.component';
import { CaptureSignatureComponent } from '../../capture-signature/capture-signature.component';
import { ThemeManager } from '../../../../managers/theme.manager';
import { PurchaseOrderPaymentType } from '../../../../services/purchase-order/purchase-order.model';
import { Contact } from '../../../../services/vendor/contact.model';
import { MessageService } from 'primeng/api';
import { environment } from '../../../../../environments/environment';
import { LocationManagerService } from '../../../../services/location/location-manager.service';

@Component({
    selector: 'ls-default-compliance-page',
    templateUrl: './default-compliance-page.component.html',
    styleUrls: [ './default-compliance-page.component.less' ]
})
export class DefaultCompliancePageComponent implements OnInit
{
    @ViewChild('signature', { static: false }) signature: CaptureSignatureComponent;

    @Output() signatureCompleted = new EventEmitter<boolean>();

    date: Date;
    idNum = '';
    idState = '';
    name = '';
    selectedPaymentTypes: PurchaseOrderPaymentType[] = [];
    selectedContact: Contact;
    vehicleLicenseNum = '';
    vehicleLicenseState = '';

    headerText = 'Purchase Agreement';

    introRegularText1 = 'IN CONSIDERATION OF THE COVENANTS and agreements contained in this Purchase Agreement, the';
    introRegularText2 = 'parties to this Agreement agree as follows:';
    partiesText1 = ' (the "Seller"), of';
    partiesText2 = 'does hereby sell, assign and transfer to';
    partiesText3: string;
    partiesText4 = 'the following property...';
    paymentTypeHeaderText = 'Payment Type:';
    sellerInfoHeaderText = 'Seller information:';
    vehicleLicenseText = 'Vehicle License # __________________________________________';
    idText = 'ID or DL # _______________________________________________';
    stateText = 'State _______________';
    signaturePrelude1 = 'I, the undersigned, affirm under penalty of law that the property that is subject to this transaction is not';
    signaturePrelude2 = 'to the best of my knowledge stolen property. I certify that I am the legal owner of all merchandise sold.';
    signaturePrelude3 = 'The undersigned also affirms that they are authorized to receive payment on behalf of the above listed commercial enterprise.';
    signatureText = 'Signature ______________________________________________________';
    printText = 'Print _________________________________________________________';
    dateText = 'Date ___________________________';

    columns = [
        { title: 'PO #', widthPercent: 35 },
        { title: 'Description', widthPercent: 15 },
        { title: 'Quantity', widthPercent: 15 },
        { title: 'Purchase Price', widthPercent: 25 },
    ];

    paymentTypes = [
        { title: 'Cash', value: PurchaseOrderPaymentType.Cash },
        { title: 'Check', value: PurchaseOrderPaymentType.Check },
        { title: 'Wire Transfer', value: PurchaseOrderPaymentType.WireTransfer }
    ];

    ThemeManager = ThemeManager;

    constructor(public poManager: PurchaseOrderManagerService,
                private locationManager: LocationManagerService,
                private messageService: MessageService,
                private currencyPipe: CurrencyPipe)
    { }

    ngOnInit(): void
    {
        // tslint:disable:max-line-length no-bitwise
        if (this.poManager.current.paymentType & PurchaseOrderPaymentType.Cash) this.selectedPaymentTypes.push(PurchaseOrderPaymentType.Cash);
        if (this.poManager.current.paymentType & PurchaseOrderPaymentType.Check) this.selectedPaymentTypes.push(PurchaseOrderPaymentType.Check);
        if (this.poManager.current.paymentType & PurchaseOrderPaymentType.WireTransfer) this.selectedPaymentTypes.push(PurchaseOrderPaymentType.WireTransfer);
        this.locationManager.getCurrentLocation().subscribe(location =>
        {
            this.partiesText3 = location.subsidiary.displayName + ' (the "Buyer"), ';
        });
        // tslint:enable:max-line-length no-bitwise */
    }

    generateSignaturePage(parent: PurchaseOrderPDFComponent): void
    {
        const vendor = parent.po.vendor;

        // Add Title
        parent.outputComplianceHeader(this.headerText);

        // ---------- SECTION 1 START ----------
        parent.pdf.setFontSize(parent.pdfDimensions.general.regularFontSize);
        parent.pdf.setFont('helvetica', null, 'normal');
        parent.outputTextLine(this.introRegularText1);
        parent.outputTextLine(this.introRegularText2);
        // ---------- END SECTION 1 ----------


        // ---------- SECTION 2 START ----------
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputTextLine(`${vendor.name} ${this.partiesText1} ${vendor.fullAddress},`);
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputTextLine(this.partiesText2);
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputTextLine(`${this.partiesText3} ${parent.po.location.fullAddress}`);
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputTextLine(this.partiesText4);
        // ---------- END SECTION 2 ----------

        // ---------- SECTION 3 START ----------
        // Use "table headers" to create the pseudo-table
        parent.createTableHeader(this.columns, true, false);
        const columnOutput = cloneDeep(this.columns);
        columnOutput[0].title = parent.po.number;
        columnOutput[1].title = 'Converters';
        columnOutput[2].title = parent.po.totalNumConverters.toString();
        columnOutput[3].title = this.currencyPipe.transform(parent.po.totalConvertersPrice, 'USD');
        parent.createTableHeader(columnOutput, true, false);
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        // ---------- END SECTION 3 ----------


        // tslint:disable:max-line-length

        // ---------- SECTION 4 START ----------
        // tslint:disable:no-bitwise
        parent.outputTextLine(this.paymentTypeHeaderText);
        parent.result.bottomOfOutput += parent.lineHeight;
        let margin = 10;
        for (const paymentType of this.paymentTypes)
        {
            parent.outputCheckbox(margin, (paymentType.value & parent.po.paymentType) !== 0);
            margin += 15;
            parent.outputText(paymentType.title, margin);
            margin += parent.textWidth(paymentType.title) + 40;
        }
        // tslint:enable:no-bitwise
        // ---------- END SECTION 4 ----------

        // ---------- SECTION 5 START ----------
        parent.result.bottomOfOutput += parent.lineHeight * 3;
        parent.outputTextLine(this.sellerInfoHeaderText);
        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputText(`${this.vehicleLicenseText}  ${this.stateText}`);
        parent.outputText(this.vehicleLicenseNum, parent.textWidth(this.removeUnderscores(this.vehicleLicenseText) + 5));
        parent.outputTextLine(this.vehicleLicenseState, parent.textWidth(`${this.vehicleLicenseText}  `) +
            parent.textWidth(this.removeUnderscores(this.stateText) + 5));

        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputText(`${this.idText}  ${this.stateText}`);
        parent.outputText(this.idNum, parent.textWidth(this.removeUnderscores(this.idText) + 5));
        parent.outputTextLine(this.idState, parent.textWidth(`${this.idText}  `) +
            parent.textWidth(this.removeUnderscores(this.stateText) + 5));
        // ---------- END SECTION 5 ----------

        // ---------- SECTION 6 START ----------
        parent.result.bottomOfOutput += parent.lineHeight * 4;
        parent.outputTextLine(this.signaturePrelude1);
        parent.outputTextLine(this.signaturePrelude2);
        parent.result.bottomOfOutput += parent.lineHeight * 1.5;
        parent.pdf.setFontSize(9);
        parent.outputTextLine(this.signaturePrelude3);
        parent.pdf.setFontSize(parent.pdfDimensions.general.regularFontSize);

        parent.result.bottomOfOutput += parent.lineHeight * 1.5;
        const imageHeight = parent.lineHeight * 2;
        const widthToHeightRatio = this.signature.widthToHeightRatio;
        parent.outputImage(this.signature.signature, 'PNG', parent.leftMargin + parent.textWidth(this.removeUnderscores(this.signatureText)), imageHeight * widthToHeightRatio, imageHeight);
        parent.outputTextLine(this.signatureText);

        parent.result.bottomOfOutput += parent.lineHeight * 2;
        parent.outputText(`${this.printText}  ${this.dateText}`);
        parent.outputText(this.name, parent.textWidth(this.removeUnderscores(this.printText) + 5));
        parent.outputTextLine(moment(this.date).format('MM-DD-yyyy'), parent.textWidth(`${this.printText}  `) +
            parent.textWidth(this.removeUnderscores(this.dateText) + 5));
        // ---------- END SECTION 6 ----------
        // tslint:enable:max-line-length

        this.clearFields();
    }

    get partiesTextHTML(): string
    {
        const vendor = this.poManager.current.vendor;
        return `<div class="party-line">${vendor.name} ${this.partiesText1} ${vendor.fullAddress},</div>` +
            `<div>${this.partiesText2}</div>` + `<div>${this.partiesText3} ${this.poManager.current.location.fullAddress}</div>` + `<div>${this.partiesText4}</div>`;
    }

    get signaturePrelude(): string
    {
        return `${this.signaturePrelude1} ${this.signaturePrelude2}`;
    }

    get signaturePreludeWarning(): string
    {
        return `${this.signaturePrelude3}`;
    }

    removeUnderscores(text: string): string
    {
        return text.replace(/_/g, '');
    }

    clearSignatures(): void
    {
        this.signature.clear();
    }

    onSelectedContactChanged(event: any): void
    {
        this.selectedContact = event.value;
        if (!this.selectedContact.isContactValidForPO) {
            this.messageService.add({ severity: 'error', summary: 'Invalid Contact', detail: `Contact has invalid driver's license information and cannot be selected.` });
            setTimeout(() => {
                this.idNum = '';
                this.idState = '';
                this.name = '';
                this.selectedContact = undefined;
            }, 100);
            return;
        }
        this.idNum = this.selectedContact.driversLicenseNumber ?? '';
        this.idState = this.selectedContact.driversLicenseState ?? '';
        this.name = this.selectedContact.fullName;
    }

    onCancel(): void
    {
        this.clearFields();
        this.signatureCompleted.emit(false);
    }

    onSave(): void
    {
        this.poManager.current.paymentType =
            // tslint:disable-next-line:no-bitwise
            (this.selectedPaymentTypes.includes(PurchaseOrderPaymentType.Cash) ? PurchaseOrderPaymentType.Cash : 0) |
            (this.selectedPaymentTypes.includes(PurchaseOrderPaymentType.Check) ? PurchaseOrderPaymentType.Check : 0) |
            (this.selectedPaymentTypes.includes(PurchaseOrderPaymentType.WireTransfer) ? PurchaseOrderPaymentType.WireTransfer : 0);
        this.signatureCompleted.emit(true);
    }

    private clearFields(): void
    {
        this.date = undefined;
        this.idNum = '';
        this.idState = '';
        this.name = '';
        this.selectedPaymentTypes = [];
        this.selectedContact = undefined;
        this.vehicleLicenseNum = '';
        this.vehicleLicenseState = '';
        this.clearSignatures();
    }
}
