import {
    HttpErrorResponse,
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent
} from '@angular/common/http';
import { Injectable, Injector, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, Observer, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { LocalCacheManager } from '../../managers/local-cache.manager';
import { LegendLogLevel, LoggingService } from '../logging.service';
import { AuthManagerService } from './auth-manager.service';
import { AuthenticatedUser } from './authenticated-user.model';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private injector: Injector,
                private ngZone: NgZone)
    { }

    private static isRefreshTokenInvalid(error: HttpErrorResponse): boolean
    {
        const parsedError = typeof(error.error) === 'string' ? JSON.parse(error.error) : error.error;
        // State when the refreshToken exists, but is expired. This will be thrown by the server.
        const errorString = error.error == null || parsedError.Error_1 == null ? '' : parsedError.Error_1[0];
        if (errorString.includes('token is expired') || errorString.includes('another device'))
        {
            return true;
        }

        // State when the refreshToken does not exist. This will be thrown by userManager.loginWithRefreshToken()
        return error.statusText === 'no refresh token found';
    }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>>
    {
        const accessToken = LocalCacheManager.accessToken;
        let copiedRequest = req;

        if ((accessToken && accessToken !== '') &&
            !req.url.toLowerCase().includes('/auth/token') &&
            !req.url.toLowerCase().includes('blob.core.windows.net') &&
            !req.url.toLowerCase().includes('currency-api.com')) {
            copiedRequest = req.clone({headers: req.headers.append('Authorization', `bearer ${accessToken}`)});
        }

        // if (accessToken && accessToken !== '')
        // {
        //     copiedRequest = req.clone({
        //         headers: req.headers.append('Authorization', 'bearer ' + accessToken)
        //         // .append('Content-Type', 'application/json')
        //             .append('Accept', 'application/json')
        //     });
        // }

        return next.handle(copiedRequest).pipe(catchError((error: Response, caught: Observable<any>) =>
        {
            // noinspection SuspiciousInstanceOfGuard
            if (error instanceof HttpErrorResponse) {
                switch ((error as HttpErrorResponse).status)
                {
                    // case 0:
                    //     return;
                    case 400:
                        return this.handle400Error(error);
                    case 401:
                        return this.handle401Error(req, next);
                    case 403:
                        LoggingService.log(LegendLogLevel.Error, 'User does not have access to requested resource');
                        return throwError(error);
                }

                return throwError(caught);
            }
            else {
                return throwError(error);
            }
        }));
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler): any
    {
        if (!this.isRefreshingToken)
        {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            const loginAndUserManager = this.injector.get(AuthManagerService);

            return loginAndUserManager.loginWithRefreshToken(loginAndUserManager.currentUser.subsidiaryId,
                loginAndUserManager.currentUser.locationId)
                .pipe(switchMap((user: AuthenticatedUser) =>
                    {
                        this.tokenSubject.next(user.token);
                        const copiedRequest = req.clone({headers: req.headers.append('Authorization', `bearer ${user.token}`)});

                        this.isRefreshingToken = false;
                        return this.ngZone.run(() => next.handle(copiedRequest).pipe(enterZone(this.ngZone)));
                    }),
                    catchError((error: any) =>
                    {
                        this.isRefreshingToken = false;
                        if (error instanceof HttpErrorResponse && ((error as HttpErrorResponse).status === 400)) {
                            // If there is an exception calling 'refreshToken', bad news so logout.
                            return this.handle400Error(error);
                        }

                        return throwError(error);
                    })
                );
        }
        else
        {
            return this.tokenSubject.pipe(filter(token => token != null), take(1), switchMap(token =>
            {
                const copiedRequest = req.clone({headers: req.headers.append('Authorization', `bearer ${token}`)});
                return next.handle(copiedRequest);
            }));
        }
    }

    handle400Error(error): any
    {
        if (error && error.status === 400 && AuthInterceptor.isRefreshTokenInvalid(error))
        {
            this.isRefreshingToken = false;

            // If we get a 400 and the token is no longer valid then logout.
            const authManager = this.injector.get(AuthManagerService);
            authManager.logout();
            return;
        }

        return throwError(error);
    }
}

function enterZone<T>(zone: NgZone): any
{
    return (source: Observable<T>) => {
        return new Observable((sink: Observer<T>) => {
            return source.subscribe({
                next(x): void { zone.run(() => sink.next(x)); },
                error(e): void { zone.run(() => sink.error(e)); },
                complete(): void { zone.run(() => sink.complete()); }
            });
        });
    };
}
