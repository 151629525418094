import { Vendor, VendorActivityStatus } from './vendor.model';
import { Utils } from '../utils.service';
import { Contact } from './contact.model';
import { UserLog } from '../user-log/user-log.model';

export class VendorMapInfo
{
    public contacts: Contact[] = [];
    public email: string;
    public id = 0;
    public isAssayVendor: boolean;
    public activityStatus = VendorActivityStatus.Unknown;
    public name: string;
    public address: string;
    public city: string;
    public state: string;
    public zip: string;
    public latitude?: number;
    public longitude?: number;
    public lastBuyerName: string;
    public sales = 0;
    public salesDates: Date[] = [];
    public userLogs: UserLog[] = [];

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
            if (json.salesDates != null) this.salesDates = json.salesDates.map(d => Utils.convertApiDateStringToDate(d));
            if (json.contacts != null) this.contacts = json.contacts.map(item => new Contact(item));
            if (json.userLogs != null) this.userLogs = json.userLogs.map(item => new UserLog(item));
        }
    }

    get cityStateZip(): string
    {
        let cityStateZip = this.city || '';
        cityStateZip += (cityStateZip.length === 0 ? '' : ', ') + (this.state ? this.state : '');
        cityStateZip += (cityStateZip.length === 0 ? '' : ' ') + (this.zip ? this.zip : '');
        return cityStateZip;
    }
}
