import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalCacheManager } from './local-cache.manager';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ThemeManager
{
    static DEFAULT_THEME = environment.deploymentName === 'Cat Daddies' ? 'light' : 'dark';
    static themeChanged = new Subject<string>();

    private static _theme: string = localStorage.Theme != null ? localStorage.Theme : ThemeManager.DEFAULT_THEME;

    constructor() {
    }

    static get theme(): string
    {
        return ThemeManager._theme;
    }

    static set theme(theme: string)
    {
        if (theme === ThemeManager._theme) return;

        ThemeManager._theme = theme;
        ThemeManager.themeChanged.next(theme);

        localStorage.Theme = theme;
    }

    static get watermarkImage(): string {
        return environment.elemental ? '/assets/images/elemental/logo.png'
            : (ThemeManager.theme === 'dark' ? '/assets/images/LSRRoundLogo_white.png' : '/assets/images/LSRRoundLogo_black.png');
    }
}
