import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';

import { filter, catchError, map, timeout } from 'rxjs/operators';

import { Observable, throwError } from 'rxjs';
import { ApiService, HttpMethod } from '../../services/api.service';
import { SasTokenModel } from './sas-token.model';

export class ImageSizeResult
{
    constructor(width: number, height: number)
    {
        this.width = width;
        this.height = height;
    }
    width: number;
    height: number;
}

@Injectable({
    providedIn: 'root'
})
export class ImageApiService extends ApiService
{
    constructor(protected http: HttpClient)
    {
        super(http);
    }

    getSasTokenUrl(filename: string): Observable<SasTokenModel>
    {
        return this.apiRequest<SasTokenModel>(this.getSasTokenUrlUrl(filename), HttpMethod.Get)
            .pipe(map(response => new SasTokenModel(response)));
    }

    uploadFile(file: File, sasUrl: string): Observable<any>
    {
        const headers = new HttpHeaders()
            .set('x-ms-blob-type', 'BlockBlob')
            .set('x-ms-blob-content-type', file.type)
            .set('Content-Type', file.type);
        const req = new HttpRequest('put', sasUrl, file, { headers, reportProgress: false });
        return this.http.request(req)
            .pipe(filter(result => result instanceof HttpResponse), catchError(err =>
            {
                const errorMessage = err.error instanceof ErrorEvent ? `Error: ${err.error.message}` :
                    `Server returned ${err.status} with message ${err.message}`;
                return throwError(errorMessage);
            }));
    }

    uploadConverterAiImage(formData: FormData): Observable<any> {
        const uploadUrl = `${this.baseUrl}/uploadConverterAiImage`;
        return this.apiUploadRequest<any>(uploadUrl, formData, 600);
    }

    // tslint:disable-next-line:member-ordering
    static b64toBlob(b64Data, contentType = '', sliceSize = 512): any
    {
        const byteCharacters = atob(b64Data.replace(/^data:application\/(pdf|jpeg|jpg);base64,/, ''));
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize)
        {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++)
            {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    // tslint:disable-next-line:member-ordering
    static isImageUrl(url: string): boolean
    {
        const lower = url.toLowerCase();
        return lower.includes('.jpg') || lower.includes('.jpeg') || lower.includes('.png');
    }

    getResizedImage(imageAssetId: number, width: number, height: number): Observable<any>
    {
        const url = this.resizedImageUrl(imageAssetId, width, height);
        return this.apiGetRequestForBlob(url);
    }

    apiGetRequestForBlob<T>(url: string): Observable<T>
    {
        const headers = new HttpHeaders().set('Content-Type', 'application/json').append('Accept', 'image/jpeg');
        return this.http.get<T>(url, { headers, responseType: 'blob' as 'json' }).pipe(timeout(15000));
    }

    checkImageSize(file: File): Observable<ImageSizeResult>
    {
        return new Observable<ImageSizeResult>(observer =>
        {
            const img = new Image();

            img.onload = () =>
            {
                const width = img.width;
                const height = img.height;
                observer.next(new ImageSizeResult(width, height));
                observer.complete();
            };

            img.src = window.URL.createObjectURL(file);
        });
    }

    // =============================================================================================================================
    // URLs
    // =============================================================================================================================
    /* tslint:disable:member-ordering */
    private get baseUrl(): string { return this.baseApiUrl + 'images'; }
    private resizedImageUrl(id: number, width: number, height: number): string
    {
        return `${this.baseUrl}/${id}/${width}/${height}/filename`;
    }
    getSasTokenUrlUrl(filename: string): string { return `${this.baseUrl}/sas/${filename}`; }
}
