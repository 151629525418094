import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Location } from './location.model';
import { LocationApiService } from './location-api.service';
import { AuthManagerService } from '../auth/auth-manager.service';

@Injectable({
    providedIn: 'root'
})
export class LocationManagerService
{
    private _selectedLocationId: number;
    private _locations: Location[] = [];
    private _currentLocation: Location;

    constructor(private locationsApiService: LocationApiService,
                private authService: AuthManagerService)
    {
        this.authService.userLoggedOut.subscribe(() => this._locations = []);
        this.authService.currentUserChanged.subscribe(user => {
            this._selectedLocationId = user.locationId;
            this._locations = [];
            this._currentLocation = undefined;
        });
    }

    get selectedLocationId(): number
    {
        return this._selectedLocationId;
    }

    set selectedLocationId(locationId: number)
    {
        this._selectedLocationId = locationId;
    }

    getCurrentLocation(): Observable<Location>
    {
        if (this._currentLocation !== undefined) { return of(this._currentLocation); }
        return this.locationsApiService.getCurrentLocation().pipe(tap(location => this._currentLocation = location));
    }

    // loadCurrentLocation(): Observable<Location>
    // {
    //     return this.locationsApiService.getLocation(0).pipe(tap(location => this.selectedLocation = location));
    // }

    getMapLocations(): Observable<Location []>
    {
        return this.locationsApiService.getMapLocations();
    }

    getLocations(): Observable<Location []>
    {
        if (this._locations.length !== 0) { return of(this._locations); }

        return this.locationsApiService.getAllLocations().pipe(tap(locations => this._locations = locations ));
    }

    saveAnnouncement(headingHtml: string, textHtml: string, updateAllLocations: boolean): Observable<boolean>
    {
        return this.locationsApiService.saveAnnouncement(headingHtml, textHtml, updateAllLocations);
    }

    saveNews(headingHtml: string, textHtml: string, updateAllLocations: boolean): Observable<boolean>
    {
        return this.locationsApiService.saveNews(headingHtml, textHtml, updateAllLocations);
    }

    // clearSelectedLocation(): void
    // {
    //     if (this._selectedLocation) { this._selectedLocation.clearLocation(); }
    // }
    //
    // selectLocation($event)
    // {
    //     this.selectedLocation = this.locations[$event.selectedIndex - 1];
    //     this.purchaseOrderManager.clearPurchaseOrderCart();
    //     this.loginAndUserManager.loginWithRefreshToken(this.selectedLocation.id).subscribe(() => this.loadCurrentLocation());
    // }
}
