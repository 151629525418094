import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiEndpointInfo } from './api-endpoint-info.model';
import { timeout } from 'rxjs/operators';

export enum HttpMethod {
    Get,
    Post,
    Put,
    Patch,
    Delete,
}

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    private defaultEndpointIndex = environment.defaultApiEndpoint;
    private apiEndpoints: ApiEndpointInfo[] = [
        new ApiEndpointInfo(
            'Legend-V2Production',
            'https://app-backend-us.elemental.biz/api/'
        ),
        new ApiEndpointInfo(
            'Legend-V2Test',
            'https://app-backend.test.elemental.biz/api/'
        ),
        new ApiEndpointInfo(
            'Legend-V2Dev',
            'https://app-backend.dev.elemental.biz/api/'
        ),
        new ApiEndpointInfo('Legend-Local', 'https://localhost:5001/api/'),
    ];
    protected baseApiUrl = this.apiEndpoints[this.defaultEndpointIndex].baseUrl;

    constructor(protected http: HttpClient) {}

    private readonly headers = new HttpHeaders().set(
        'Content-Type',
        'application/json'
    );

    apiRequest<T>(
        url: string,
        httpMethod: HttpMethod,
        httpBody?: any,
        timeoutInSecs = 15
    ): Observable<T> {
        if (url.length > 2080) {
            const urlWithoutParams = url.split('?');
            throw new URIError(
                `apiRequest URI (${urlWithoutParams[0]}) is too long. This may indicate an API error. Consult the factory.`
            );
        }
        return this.http
            .request<T>(HttpMethod[httpMethod], url, {
                headers: this.headers,
                body: httpBody,
            })
            .pipe(timeout(timeoutInSecs * 1000));
    }

    apiUploadRequest<T>(
        url: string,
        formData: FormData,
        timeoutInSecs: number
    ): Observable<T> {
        return this.http
            .post<T>(url, formData)
            .pipe(timeout(timeoutInSecs * 1000));
    }

    apiGetRequestForCsv<T>(url: string): Observable<any> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Accept', 'text/csv');
        return this.http
            .get<T>(url, { headers, responseType: 'blob' as 'json' })
            .pipe(timeout(900000));
    }
}
