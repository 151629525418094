import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Subsidiary } from './subsidiary.model';
import { SubsidiaryApiService } from './subsidiary-api.service';
import { AuthManagerService } from '../auth/auth-manager.service';

@Injectable({
    providedIn: 'root'
})
export class SubsidiaryManagerService
{
    private _selectedSubsidiaryId: number;
    private _subsidiaries: Subsidiary[] = [];

    constructor(private subsidiariesApiService: SubsidiaryApiService,
                private authService: AuthManagerService)
    {
        this.authService.userLoggedOut.subscribe(() => this._subsidiaries = []);
        this.authService.currentUserChanged.subscribe(user => {
            this._selectedSubsidiaryId = user.subsidiaryId;
            this._subsidiaries = [];
        });
    }

    get selectedSubsidiaryId(): number
    {
        return this._selectedSubsidiaryId;
    }

    set selectedSubsidiaryId(subsidiaryId: number)
    {
        this._selectedSubsidiaryId = subsidiaryId;
    }

    getSubsidiaries(): Observable<Subsidiary []>
    {
        if (this._subsidiaries.length !== 0) { return of(this._subsidiaries); }

        return this.subsidiariesApiService.getAllSubsidiaries().pipe(tap(subsidiaries => this._subsidiaries = subsidiaries ));
    }
}
