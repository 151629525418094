import { UntypedFormGroup } from '@angular/forms';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { AppInjector } from '../../core/core.module';
import { ProfitTierGroup } from '../../services/pricing/profit-tier.model';
import * as moment from 'moment';

export abstract class FormUtils {
    private static _datePipe: DatePipe;
    private static get datePipe(): DatePipe
    {
        if (this._datePipe == null) this._datePipe = AppInjector.get(DatePipe);
        return this._datePipe;
    }

    static getFormValues(fg: UntypedFormGroup, removeUndefined: boolean = false): any {
        const changedProperties = [];
        Object.keys(fg.controls).forEach(name => {
            const currentControl = fg.controls[name];
            changedProperties.push( {
                name,
                value: currentControl instanceof UntypedFormGroup ? this.getFormValues(currentControl) : currentControl.value,
            });
        });
        return changedProperties.length === 0 ? null : changedProperties.reduce((map, obj) => {
            if (!removeUndefined || obj.value !== undefined) {
                map[obj.name] = obj.value;
            }
            return map;
        }, {});
    }

    static forceValueUpdateForForm(form: UntypedFormGroup, value: any, controlName: string): void
    {
        if (form == null) return;
        const control = form.get(controlName);
        if (control == null) return;
        control.setValue(value, { emitEvent: true });
        control.markAsDirty();
    }

    static formatDateForDisplay(date: Date): string
    {
        return this.datePipe.transform(date, 'MM/dd/yy');
    }

    static formatDateTimeForDisplay(date: Date): string
    {
        return this.datePipe.transform(date, 'MM/dd/yy hh:mma');
    }

    static getTimeAgo(minutesAgo: number): string
    {
        if (minutesAgo < 0) return 'N/A';
        if (minutesAgo < 60) return `${minutesAgo} min. ago`;
        if (minutesAgo < 60 * 24) return `${Math.floor(minutesAgo / 60)} hour(s) ago`;
        return `${Math.floor(minutesAgo / (60 * 24))} day(s) ago`;
    }
}
