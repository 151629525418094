export enum PermissionType
{
    EditUserList,
    EditUserProperties,
    EditUserPermissions,
    AssignUserToLocation,
    AssignBuyerToVendor,

    SetPricesForLocation,
    SetPricesForVendor,
    SetPricesForBuyer,
    SetPricesForAssayVendors,
    SetPricesForConventionalVendors,

    SetProfitMarginGlobal,
    SetProfitMarginForBuyer,

    CreateHedge,
    AssignHedgeToBuyer,
    HideMarketPricing,
    SetProfitMarginOnly,

    CreateVendor, // Currently unused - EditVendorProperties is used instead
    DeleteVendor,
    EditVendorProperties,
    UpdateVendorLicenseInfo, // Currently unused - EditVendorProperties is used instead
    AssignVendorAsAssay,
    AllowVendorToCreatePOs,

    CreatePurchaseOrder,
    DeletePurchaseOrder,
    UnlockPurchaseOrder,
    ExportPurchaseOrder,

    EditPartsList,
    ViewMiscParts,
    ViewCategoryParts,
    ViewNoNumberParts,
    ViewPriceSheet,
    ViewNoNumberLink,

    ViewAllPOsForLocation,
    ViewCalculator,

    GenerateReports,
    CreateLogs,
    ViewMap,
    SearchByVIN,
    ViewVendorsAndUsers,
    ViewTasks
}

export class Permission
{
    permissionType: PermissionType;
    permissionTypeString: string;
    alwaysProvided: boolean;
    defaultOn: boolean;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
        }
    }
}

export class UserPermissions
{
    roles: string[];
    permissions: PermissionType[];

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
        }
    }
}
