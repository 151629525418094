<!--suppress TypeScriptUnresolvedVariable -->
<div *ngIf="constructor.name.length > 1" class="component-name">>> {{constructor.name}} <<</div>

<div *ngIf="currentUser != null" class="wrapper" [class.no-subsidiary]="!currentUser.subsidiaryId" (click)="menu.toggle($event)">
    <div *ngIf="!deviceManager.isMobile"><i class="fa fa-layer-group"></i></div>
    <div class="subsidiary-name-area">
        <div *ngIf="deviceManager.isMobile" class="subsidiary-label">Subsidiary: </div>
        <div *ngIf="currentUser.subsidiaryId != null" class="subsidiary-text">{{subsidiaryName}}</div>
    </div>
</div>

<p-menu #menu [popup]="true" [model]="subsidiaryItems"></p-menu>
