import { Utils } from '../utils.service';
import * as moment from 'moment/moment';

export class Contact
{
    public id = 0;
    public firstName: string;
    public lastName: string;
    public middleInitial: string;
    public driversLicenseNumber: string;
    public driversLicenseState: string;
    public stateIdCardNumber: string;
    public email: string;
    public phone: string;
    public driversLicenseImageUrl: string;
    public driversLicenseExpiration: Date;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
            if (json.driversLicenseExpiration != null)
                this.driversLicenseExpiration = Utils.convertApiUtcDateStringToDate(json.driversLicenseExpiration);
        }
    }

    get fullName(): string { return `${this.firstName || ''} ${this.lastName || ''}`.trim(); }

    get isLicenseExpirationValid(): boolean {
        if (this.driversLicenseExpiration == null) return false;

        const m = moment(this.driversLicenseExpiration);
        const tomorrow = moment().startOf('day').add(1, 'day');
        return m.isValid() && m >= tomorrow && m.year() <= 2050;
    }

    get isContactValidForPO(): boolean
    {
        return this.isLicenseExpirationValid &&
            this.driversLicenseImageUrl != null && this.driversLicenseImageUrl.length > 0 &&
            this.driversLicenseNumber != null && this.driversLicenseNumber.length > 0 &&
            this.driversLicenseState != null && this.driversLicenseState.length > 0;
    }
}
