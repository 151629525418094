import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';

import { ConfirmationService, MessageService } from 'primeng/api';

import { PurchaseOrder } from './purchase-order.model';
import { PurchaseOrderApiService } from './purchase-order-api.service';
import { PurchaseOrdersList } from './purchase-orders-list.model';
import { LocalCacheManager } from '../../managers/local-cache.manager';
import { PurchaseOrderLineItem, PurchaseOrderLineItemMiscellaneous } from './purchase-order-line-item.model';
import {
    PurchaseOrderTransaction,
    PurchaseOrderTransactionActionType,
    PurchaseOrderTransactionPartType
} from './purchase-order-transaction';
import { AuthManagerService } from '../auth/auth-manager.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Converter } from '../converter/converter.model';
import { MiscellaneousPart, PricingType } from '../miscellaneous-part/miscellaneous-part.model';
import { ExportedPurchaseOrderModel } from './exported-purchase-order.model';
import { ImageApiService } from '../../shared-api/images/image-api.service';
import { VendorManagerService } from '../vendor/vendor-manager.service';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class PurchaseOrderManagerService implements OnDestroy
{
    purchaseOrderCreated = new Subject<PurchaseOrder>();
    purchaseOrderDeleted = new Subject<number>();
    purchaseOrderUpdated = new Subject<PurchaseOrder>();

    private _current: PurchaseOrder;

    private locationId: number;
    private transactionQueue: PurchaseOrderTransaction[] = [];
    private _uploadingTransactions = false;
    get uploadingTransactions(): boolean { return this._uploadingTransactions; }

    private static getMessageStringForTransaction(transaction: PurchaseOrderTransaction): string
    {
        let actionString = 'Unknown';
        switch (transaction.type)
        {
            case PurchaseOrderTransactionActionType.Add: actionString = 'added'; break;
            case PurchaseOrderTransactionActionType.Update: actionString = 'updated'; break;
            case PurchaseOrderTransactionActionType.Delete: actionString = 'deleted'; break;
        }

        let partType = 'Unknown';
        switch (transaction.partType)
        {
            case PurchaseOrderTransactionPartType.Converter: partType = 'Converter'; break;
            case PurchaseOrderTransactionPartType.Custom: partType = 'Custom Converter'; break;
            case PurchaseOrderTransactionPartType.Category: partType = 'No Number Converter'; break;
            case PurchaseOrderTransactionPartType.Miscellaneous: partType = 'Non-Ferrous Part'; break;
        }

        let level: string;
        switch (transaction.partType)
        {
            case PurchaseOrderTransactionPartType.Converter:
                level = PurchaseOrderLineItem.getLevelPercentageText(transaction.levelPercentage, transaction.byPound);
                break;
            case PurchaseOrderTransactionPartType.Category:
                if (!transaction.categoryHasCustomPrices) level =
                    PurchaseOrderLineItem.getLevelPercentageText(transaction.levelPercentage, transaction.byPound);
                break;
        }

        return `${transaction.partNumber}${level == null ? '' : (' (' + level + ')')} was ${actionString}`;
    }

    constructor(private router: Router,
                private authManager: AuthManagerService,
                private confirmationService: ConfirmationService,
                private purchaseOrderApiService: PurchaseOrderApiService,
                private vendorManager: VendorManagerService,
                private imageApiService: ImageApiService,
                private messageService: MessageService)
    {
        this._current = LocalCacheManager.currentPO;
        this.locationId = this.authManager.currentUser.locationId;

        this.authManager.currentUserChanged.pipe(untilDestroyed(this)).subscribe(user =>
        {
            if (user.locationId !== this.locationId)
            {
                this.locationId = user.locationId;
                this.setCurrentPO(null);
            }
        });
        this.authManager.userLoggedOut.pipe(untilDestroyed(this)).subscribe(() =>
        {
            this.locationId = null;
            this.setCurrentPO(null);
        });

        this.vendorManager.contactAdded.pipe(untilDestroyed(this)).subscribe(info =>
        {
            if (info.vendorId !== this._current?.vendorId) return;
            this._current.vendor.contacts.push(info.contact);
            LocalCacheManager.currentPO = this._current;
        });

        this.vendorManager.contactUpdated.pipe(untilDestroyed(this)).subscribe(info =>
        {
            if (info.vendorId !== this._current?.vendorId) return;
            const contact = this._current.vendor.contacts.find(c => c.id === info.contact.id);
            if (contact === undefined)
                this._current.vendor.contacts.push(info.contact);
            else
                Object.assign(contact, info.contact);
            LocalCacheManager.currentPO = this._current;
        });

        this.vendorManager.contactDeleted.pipe(untilDestroyed(this)).subscribe(info =>
        {
            if (info.vendorId !== this._current?.vendorId) return;
            this._current.vendor.contacts = this._current.vendor.contacts.filter(c => c.id !== info.contactId);
            LocalCacheManager.currentPO = this._current;
        });

        this.transactionQueue = LocalCacheManager.transactionQueue;
    }

    ngOnDestroy(): void { }

    //region Getters/Setters
    get pendingTransactionsExist(): boolean { return this.transactionQueue.length > 0; }

    get current(): PurchaseOrder { return this._current; }
    setCurrentPO(id: number): Observable<PurchaseOrder>
    {
        if (id == null)
        {
            this._current = null;
            LocalCacheManager.currentPO = null;
            this.purchaseOrderUpdated.next(null);
            return of(null);
        }

        return this.getPurchaseOrder(id).pipe(tap(po =>
        {
            this._current = po;
            LocalCacheManager.currentPO = po;
            this.purchaseOrderUpdated.next(po);
        }));
    }

    currentPOUpdated(): void
    {
        LocalCacheManager.currentPO = this._current;
        this.purchaseOrderUpdated.next(this._current);
    }

    private updateCurrent(po: PurchaseOrder): void
    {
        this._current = po;
        LocalCacheManager.currentPO = this._current;
        this.purchaseOrderUpdated.next(po);
    }
    //endregion

    //region Get API calls
    getPurchaseOrder(id: number): Observable<PurchaseOrder>
    {
        return this.purchaseOrderApiService.getPurchaseOrder(id);
    }

    refreshCurrentPurchaseOrder(): Observable<PurchaseOrder>
    {
        if (this._current == null) return of(null);
        return this.setCurrentPO(this._current.id);
    }

    getAll(pageIndex: number = 0, pageSize: number = 0, sortField: string = null, sortOrder: number = 1): Observable<PurchaseOrdersList>
    {
        return this.purchaseOrderApiService.getAll(pageIndex, pageSize, sortField, sortOrder);
    }

    getAllVendorPOs(pageIndex: number = 0, pageSize: number = 0, sortField: string = null, sortOrder: number = 1):
        Observable<PurchaseOrdersList>
    {
        return this.purchaseOrderApiService.getAllVendorPOs(pageIndex, pageSize, sortField, sortOrder);
    }

    getInRange(startIndex: number = 0, numItems: number = 0, sortField: string = null, sortOrder: number = 1):
        Observable<PurchaseOrdersList>
    {
        return this.purchaseOrderApiService.getInRange(startIndex, numItems, sortField, sortOrder);
    }

    searchPurchaseOrdersByDate(startDate: Date, endDate: Date, excludeExported: boolean, searchingCOGS: boolean,
                               excludeUnlocked: boolean):
        Observable<PurchaseOrdersList>
    {
        return this.purchaseOrderApiService.searchPurchaseOrdersByDate(startDate, endDate, excludeExported, searchingCOGS,
            excludeUnlocked);
    }

    searchCashPurchaseOrdersByDate(startDate: Date, endDate: Date): Observable<PurchaseOrdersList>
    {
        return this.purchaseOrderApiService.searchCashPurchaseOrdersByDate(startDate, endDate);
    }
    //endregion

    //region PO-level operations
    createPO(vendorId: number): Observable<PurchaseOrder>
    {
        return this.purchaseOrderApiService.createPO(vendorId).pipe(tap(po =>
        {
            this.updateCurrent(po);
            this.purchaseOrderCreated.next(po);
            this.router.navigate([ '/converters/part-number' ]);
        }));
    }

    deletePO(id: number): Observable<boolean>
    {
        return this.purchaseOrderApiService.deletePO(id).pipe(tap(() =>
        {
            this.purchaseOrderDeleted.next(id);
            this.messageService.add({ severity: 'success', summary: 'Deleted', detail: 'Purchase Order Deleted successfully' });
        }));
    }


    changeVendor(vendorId: number): Observable<PurchaseOrder>
    {
        return this.purchaseOrderApiService.changeVendor(this._current.id, vendorId).pipe(tap(po =>
        {
            this.updateCurrent(po);
            this.messageService.add({ severity: 'success', summary: 'Updated', detail: 'Vendor Changed Successfully' });
        }));
    }

    setPurchaseDate(date: Date): Observable<PurchaseOrder>
    {
        return this.purchaseOrderApiService.setPurchaseDate(this._current.id, date).pipe(tap(po =>
        {
            this.updateCurrent(po);
            this.messageService.add({ severity: 'success', summary: 'Updated', detail: 'Purchase Date Updated Successfully' });
        }));
    }

    updatePrices(): Observable<PurchaseOrder>
    {
        return this.purchaseOrderApiService.updatePrices(this._current.id).pipe(tap(po =>
        {
            this.updateCurrent(po);
            this.messageService.add({ severity: 'success', summary: 'Updated', detail: 'Purchase updated with latest prices.' });
        }));
    }

    lock(): Observable<PurchaseOrder>
    {
        return this.purchaseOrderApiService.lock(this._current.id).pipe(tap(po =>
        {
            this.updateCurrent(po);
            this.messageService.add({ severity: 'success', summary: 'Locked', detail: 'Locked' });
        }));
    }

    uploadSignaturePageAndLock(id: number, file: File): Observable<{ url: string, success: boolean }>
    {
        return this.purchaseOrderApiService.getSasTokenUrl(file.name).pipe(switchMap(model =>
        {
            if (model == null || model.token == null) return throwError('Unable to get upload URL for signature page.');

            return this.imageApiService.uploadFile(file, model.token).pipe(switchMap((result) =>
            {
                if (result.status !== 201) return throwError('Unable to upload signature page.');
                return this.purchaseOrderApiService.setSignaturePageAndLock(id, model.token).pipe(map(po =>
                {
                    this.updateCurrent(po);
                    return {
                        url: model.token.substr(0, model.token.indexOf('?')),
                        success: true
                    };
                }));
            }));
        }));
    }

    clearExtraPages(id: number): Observable<{ url: string, success: boolean }>
    {
        return this.purchaseOrderApiService.clearExtraPages(id).pipe(map(result =>
        {
            this._current.extraPagesUrl = null;
            return {
                url: undefined,
                success: result
            };
        }));
    }

    uploadExtraPages(id: number, file: File): Observable<{ url: string, success: boolean }>
    {
        return this.purchaseOrderApiService.getSasTokenUrl(file.name).pipe(switchMap(model =>
        {
            if (model == null || model.token == null) return throwError('Unable to get upload URL for extra pages.');

            return this.imageApiService.uploadFile(file, model.token).pipe(switchMap((result) =>
            {
                if (result.status !== 201) return throwError('Unable to upload extra pages.');
                return this.purchaseOrderApiService.setExtraPages(id, model.token).pipe(map(result =>
                {
                    this._current.extraPagesUrl = model.token.substr(0, model.token.indexOf('?'));
                    return {
                        url: this._current.extraPagesUrl,
                        success: result
                    };
                }));
            }));
        }));
    }

    updatePaymentType(): Observable<boolean>
    {
        return this.purchaseOrderApiService.updatePaymentType(this._current.id, this._current.paymentType);
    }

    retryTransactions(): Observable<boolean>
    {
        return this.processTransactionQueue();
    }

    clearTransactionQueue(): void
    {
        this.transactionQueue = [];
        LocalCacheManager.transactionQueue = this.transactionQueue;
    }

    unlock(): Observable<void>
    {
        return this.purchaseOrderApiService.unlock(this._current.id).pipe(tap(() =>
        {
            this._current.isLocked = false;
            this._current.signaturePageUrl = null;
            this.updateCurrent(this._current);
            this.messageService.add({ severity: 'success', summary: 'Unlocked', detail: 'Unlocked' });
        }));
    }

    public emailPdf(base64: string): Observable<any>
    {
        return this.purchaseOrderApiService.emailPdf(this._current.id, base64);
    }

    public outputPurchaseOrderAsWordDoc(id: number): Observable<any>
    {
        return this.purchaseOrderApiService.outputPurchaseOrderAsWordDoc(id);
    }

    uploadImage(id: number, file: File): Observable<any>
    {
        const parts = file.name.split('.');
        const cleanFileName = parts[0].toLowerCase().replace(/ /g, '_') + `_${new Date().getTime()}` + `.${parts[1]}`;
        return this.purchaseOrderApiService.getSasTokenUrl(cleanFileName).pipe(switchMap(model =>
        {
            if (model == null || model.token == null) return throwError('Unable to get upload URL.');

            return this.imageApiService.uploadFile(file, model.token).pipe(switchMap(result =>
            {
                if (result.status !== 201) return of(true);
                return this.purchaseOrderApiService.addImage(id, model.token);
            }));
        }));
    }

    removeImage(id: number, url: string): Observable<any>
    {
        return this.purchaseOrderApiService.removeImage(id, url);
    }
    //endregion

    //region Line Item level operations
    addConverterDirect(partType: PurchaseOrderTransactionPartType, converter: Converter, converterGroupName: string,
                       pricePerItem: number, quantity: number, categoryHasCustomPrices: boolean, levelPercentage: number)
        : Observable<boolean>
    {
        // Create a single item transaction queue
        const transaction = new PurchaseOrderTransaction({
            purchaseOrderId: this._current.id, type: PurchaseOrderTransactionActionType.Add, partType, partNumber: converter.partNumber,
            itemId: converter.id, lineItemId: null, pricePerItem, quantity, categoryHasCustomPrices, levelPercentage,
            byPound: partType === PurchaseOrderTransactionPartType.Category && converter.byPound,
        });
        const transactionId = this.addTransactionToQueue(transaction);
        const poTransactions = this.transactionQueue;
        this._uploadingTransactions = true;
        return this.purchaseOrderApiService.uploadTransactions(poTransactions).pipe(
            finalize(() =>
            {
                this._uploadingTransactions = false;
                this.transactionQueue = [];
                LocalCacheManager.transactionQueue = this.transactionQueue;
            }),
            map(resultIds =>
            {
                poTransactions.forEach((processedTransaction, index) =>
                {
                    this.addTransactionItemToPO(converter, converterGroupName, transactionId, processedTransaction);
                    // Update the IDs of the items that were Added
                    if (processedTransaction.type === PurchaseOrderTransactionActionType.Add)
                    {
                        const updatedId = resultIds[index];
                        const list = this.getListForPartType(processedTransaction.partType) as any[];
                        const item = list.find(i => i.id === processedTransaction.transactionId);
                        if (item != null) item.id = updatedId;
                    }

                    // Update
                    this.messageService.add({
                        severity: 'success',
                        summary: 'PO Update Succeeded',
                        detail: PurchaseOrderManagerService.getMessageStringForTransaction(processedTransaction),
                    });
                });

                this._current.updateQuantitiesAndPrices();
                this.updateCurrent(this._current);
                return true;
            }), catchError(() =>
            {
                this.handleTransactionError();
                return of(false);
            }));
    }

    addConverter(partType: PurchaseOrderTransactionPartType, converter: Converter, converterGroupName: string,
                 pricePerItem: number, quantity: number, categoryHasCustomPrices: boolean, levelPercentage: number): Observable<boolean>
    {
        const transaction = new PurchaseOrderTransaction({
            purchaseOrderId: this._current.id, type: PurchaseOrderTransactionActionType.Add, partType, partNumber: converter.partNumber,
            itemId: converter.id, lineItemId: null, pricePerItem, quantity, categoryHasCustomPrices, levelPercentage,
            byPound: partType === PurchaseOrderTransactionPartType.Category && converter.byPound,
        });
        const transactionId = this.addTransactionToQueue(transaction);
        console.log(`Adding transaction: ${transactionId}`);
        this.addTransactionItemToPO(converter, converterGroupName, transactionId, transaction);

        return this.updatePOAndProcessQueue();
    }

    updateConverterDirect(item: PurchaseOrderLineItem): Observable<boolean>
    {
        if (this.hasUpdateConflict(item)) return of(false);

        const transaction = new PurchaseOrderTransaction({
            purchaseOrderId: this._current.id, type: PurchaseOrderTransactionActionType.Update, partType: item.type, itemId: null,
            partNumber: item.converterPartNumber, lineItemId: item.id, pricePerItem: item.pricePerItem, quantity: item.quantity,
            categoryHasCustomPrices: item.categoryHasCustomPrices, levelPercentage: item.levelPercentage, byPound: item.byPound,
        });
        this.addTransactionToQueue(transaction);

        return this.purchaseOrderApiService.uploadTransactions(this.transactionQueue).pipe(
            finalize(() =>
            {
                this._uploadingTransactions = false;
                this.transactionQueue = [];
                LocalCacheManager.transactionQueue = this.transactionQueue;
            }),
            map(() =>
            {
                this.updateItemInPO(item);
                return true;
            }), catchError(() =>
            {
                this.handleTransactionError();
                return of(false);
            }));
    }

    updateConverter(item: PurchaseOrderLineItem): Observable<boolean>
    {
        if (this.hasUpdateConflict(item)) return of(false);

        const transaction = new PurchaseOrderTransaction({
            purchaseOrderId: this._current.id, type: PurchaseOrderTransactionActionType.Update, partType: item.type, itemId: null,
            partNumber: item.converterPartNumber, lineItemId: item.id, pricePerItem: item.pricePerItem, quantity: item.quantity,
            categoryHasCustomPrices: item.categoryHasCustomPrices, levelPercentage: item.levelPercentage, byPound: item.byPound,
        });
        this.addTransactionToQueue(transaction);
        this.updateItemInPO(item);
        return this.updatePOAndProcessQueue();
    }

    deleteConverterOrPartDirect(partType: PurchaseOrderTransactionPartType,
                                item: PurchaseOrderLineItem | PurchaseOrderLineItemMiscellaneous): Observable<boolean>
    {
        const transaction = new PurchaseOrderTransaction({
            purchaseOrderId: this._current.id, type: PurchaseOrderTransactionActionType.Delete, partType, lineItemId: item.id,
            levelPercentage: (item instanceof PurchaseOrderLineItem ? item.levelPercentage : 1),
            partNumber: (item instanceof PurchaseOrderLineItem ? item.converterPartNumber : item.description),
            byPound: item.byPound,
        });
        this.addTransactionToQueue(transaction);

        return this.purchaseOrderApiService.uploadTransactions(this.transactionQueue).pipe(
            finalize(() =>
            {
                this._uploadingTransactions = false;
                this.transactionQueue = [];
                LocalCacheManager.transactionQueue = this.transactionQueue;
            }),
            map(() =>
            {
                this.deleteLineItemFromPO(partType, item);
                this._current.updateQuantitiesAndPrices();
                this.updateCurrent(this._current);
                return true;
            }), catchError(() =>
            {
                this.handleTransactionError();
                return of(false);
            }));
    }

    deleteConverterOrPart(partType: PurchaseOrderTransactionPartType,
                          item: PurchaseOrderLineItem | PurchaseOrderLineItemMiscellaneous): Observable<boolean>
    {
        const transaction = new PurchaseOrderTransaction({
            purchaseOrderId: this._current.id, type: PurchaseOrderTransactionActionType.Delete, partType, lineItemId: item.id,
            levelPercentage: (item instanceof PurchaseOrderLineItem ? item.levelPercentage : 1),
            partNumber: (item instanceof PurchaseOrderLineItem ? item.converterPartNumber : item.description),
            byPound: item.byPound,
        });
        this.addTransactionToQueue(transaction);
        this.deleteLineItemFromPO(partType, item);

        return this.updatePOAndProcessQueue();
    }

    addMiscPartDirect(part: MiscellaneousPart, pricePerItem: number, quantity: number): Observable<boolean>
    {
        // Create a single item transaction queue
        const transaction = new PurchaseOrderTransaction({
            purchaseOrderId: this._current.id, type: PurchaseOrderTransactionActionType.Add, partNumber: part.description,
            partType: PurchaseOrderTransactionPartType.Miscellaneous, itemId: part.id, pricePerItem, quantity,
            byPound: part.pricingType === PricingType.ByPound
        });
        const transactionId = this.addTransactionToQueue(transaction);
        const poTransactions = this.transactionQueue;
        this._uploadingTransactions = true;
        return this.purchaseOrderApiService.uploadTransactions(poTransactions).pipe(
            finalize(() =>
            {
                this._uploadingTransactions = false;
                this.transactionQueue = [];
                LocalCacheManager.transactionQueue = this.transactionQueue;
            }),
            map(resultIds =>
            {
                const list = this._current.lineItemsMiscellaneous;
                poTransactions.forEach((processedTransaction, index) =>
                {
                    const existingItemIndex = list.findIndex(li => li.partId === part.id && li.pricePerItem === pricePerItem);
                    if (existingItemIndex !== -1)
                    {
                        const existingItem = list[existingItemIndex];
                        existingItem.quantity += quantity;
                        existingItem.lastUpdated = new Date();
                        existingItem.updateTotalPrice();

                        // Move to top of list
                        list.splice(existingItemIndex, 1);
                        list.unshift(existingItem as any);
                    }
                    else
                    {
                        const newItem = new PurchaseOrderLineItemMiscellaneous({ id: transactionId, partId: part.id, quantity, pricePerItem,
                            description: part.description, pricingType: part.pricingType });
                        newItem.lastUpdated = new Date();
                        newItem.updateTotalPrice();
                        list.unshift(newItem as any);
                    }

                    // Update the IDs of the items that were Added
                    if (processedTransaction.type === PurchaseOrderTransactionActionType.Add)
                    {
                        const updatedId = resultIds[index];
                        const item = list.find(i => i.id === processedTransaction.transactionId);
                        if (item != null) item.id = updatedId;
                    }

                    // Update
                    this.messageService.add({
                        severity: 'success',
                        summary: 'PO Update Succeeded',
                        detail: PurchaseOrderManagerService.getMessageStringForTransaction(processedTransaction),
                    });
                });

                this._current.updateQuantitiesAndPrices();
                this.updateCurrent(this._current);
                return true;
            }), catchError(() =>
            {
                this.handleTransactionError();
                return of(false);
            }));
    }

    addMiscPart(part: MiscellaneousPart, pricePerItem: number, quantity: number): Observable<boolean>
    {
        const transaction = new PurchaseOrderTransaction({
            purchaseOrderId: this._current.id, type: PurchaseOrderTransactionActionType.Add, partNumber: part.description,
            partType: PurchaseOrderTransactionPartType.Miscellaneous, itemId: part.id, pricePerItem, quantity,
            byPound: part.pricingType === PricingType.ByPound
        });
        const transactionId = this.addTransactionToQueue(transaction);

        const list = this._current.lineItemsMiscellaneous;
        const existingItemIndex = list.findIndex(li => li.partId === part.id && li.pricePerItem === pricePerItem);
        if (existingItemIndex !== -1)
        {
            const existingItem = list[existingItemIndex];
            existingItem.quantity += quantity;
            existingItem.lastUpdated = new Date();
            existingItem.updateTotalPrice();

            // Move to top of list
            list.splice(existingItemIndex, 1);
            list.unshift(existingItem as any);
        }
        else
        {
            const newItem = new PurchaseOrderLineItemMiscellaneous({ id: transactionId, partId: part.id, quantity, pricePerItem,
                description: part.description, pricingType: part.pricingType });
            newItem.lastUpdated = new Date();
            newItem.updateTotalPrice();
            list.unshift(newItem as any);
        }

        this._current.updateQuantitiesAndPrices();
        this.updateCurrent(this._current);

        return this.updatePOAndProcessQueue();
    }

    updateMiscPartDirect(item: PurchaseOrderLineItemMiscellaneous): Observable<boolean>
    {
        const transaction = new PurchaseOrderTransaction({
            purchaseOrderId: this._current.id, type: PurchaseOrderTransactionActionType.Update, partNumber: item.description,
            partType: PurchaseOrderTransactionPartType.Miscellaneous,
            lineItemId: item.id, pricePerItem: item.pricePerItem, quantity: item.quantity, byPound: item.byPound,
        });
        this.addTransactionToQueue(transaction);
        const poTransactions = this.transactionQueue;
        return this.purchaseOrderApiService.uploadTransactions(poTransactions).pipe(
            finalize(() =>
            {
                this._uploadingTransactions = false;
                this.transactionQueue = [];
                LocalCacheManager.transactionQueue = this.transactionQueue;
            }),
            map(() =>
            {
                poTransactions.forEach((processedTransaction) =>
                {
                    // Replace the item in the list
                    item.updateTotalPrice();
                    item.lastUpdated = new Date();
                    this._current.lineItemsMiscellaneous[this._current.lineItemsMiscellaneous.findIndex(li => li.id === item.id)] = item;
                    this._current.lineItemsMiscellaneous = [...this._current.lineItemsMiscellaneous]
                        .sort((a, b) => b.lastUpdated.getTime() - a.lastUpdated.getTime());

                    // Update
                    this.messageService.add({
                        severity: 'success',
                        summary: 'PO Update Succeeded',
                        detail: PurchaseOrderManagerService.getMessageStringForTransaction(processedTransaction),
                    });
                });

                this._current.updateQuantitiesAndPrices();
                this.updateCurrent(this._current);
                return true;
            }), catchError(() =>
            {
                this.handleTransactionError();
                return of(false);
            }));
    }

    updateMiscPart(item: PurchaseOrderLineItemMiscellaneous): Observable<boolean>
    {
        const transaction = new PurchaseOrderTransaction({
            purchaseOrderId: this._current.id, type: PurchaseOrderTransactionActionType.Update, partNumber: item.description,
            partType: PurchaseOrderTransactionPartType.Miscellaneous,
            lineItemId: item.id, pricePerItem: item.pricePerItem, quantity: item.quantity, byPound: item.byPound,
        });
        this.addTransactionToQueue(transaction);

        // Replace the item in the list
        item.updateTotalPrice();
        item.lastUpdated = new Date();
        this._current.lineItemsMiscellaneous[this._current.lineItemsMiscellaneous.findIndex(li => li.id === item.id)] = item;
        this._current.lineItemsMiscellaneous = [...this._current.lineItemsMiscellaneous]
            .sort((a, b) => b.lastUpdated.getTime() - a.lastUpdated.getTime());

        this._current.updateQuantitiesAndPrices();
        this.updateCurrent(this._current);

        return this.updatePOAndProcessQueue();
    }
    //endregion

    //region Exporting
    public exportPurchaseOrders(purchaseOrderIds: number[], generateCOGS: boolean, nonFerrousOnly: boolean):
        Observable<ExportedPurchaseOrderModel>
    {
        return this.purchaseOrderApiService.exportPurchaseOrders(purchaseOrderIds, generateCOGS, nonFerrousOnly);
    }

    public exportPurchaseOrdersWithPGMData(purchaseOrderIds: number[]): Observable<ExportedPurchaseOrderModel>
    {
        return this.purchaseOrderApiService.exportPurchaseOrdersWithPGMData(purchaseOrderIds);
    }

    public exportExtraPagesZip(purchaseOrderId: number[]): Observable<ExportedPurchaseOrderModel>
    {
        return this.purchaseOrderApiService.exportExtraPagesZip(purchaseOrderId);
    }

    // public exportAllPurchaseOrders(startDate: Date, endDate: Date): Observable<ExportedPurchaseOrderModel>
    // {
    //     return this.purchaseOrderApiService.exportAllPurchaseOrders(purchaseOrderSearchByDate);
    // }
    //endregion

    //region Transaction Queue handling
    private processTransactionQueue(): Observable<boolean>
    {
        console.log(`processing ${this.transactionQueue.length} queue items: ${this._uploadingTransactions}`);
        if (this.transactionQueue.length === 0 || this._uploadingTransactions) return of(true);
        this._uploadingTransactions = true;

        const poTransactions = this.transactionQueue.filter(item => item.purchaseOrderId === this.transactionQueue[0].purchaseOrderId);
        console.log(`uploading: ${poTransactions.length} transactions`);
        return this.purchaseOrderApiService.uploadTransactions(poTransactions).pipe(finalize(() => this._uploadingTransactions = false),
            switchMap(resultIds =>
        {
            console.log(`finished processing ${this.transactionQueue.length} queue items: ${this._uploadingTransactions}`);
            this.transactionQueue = this.transactionQueue.filter(item => !poTransactions.some(t => t.transactionId === item.transactionId));
            console.log(`finished processing2 ${this.transactionQueue.length} queue items: ${this._uploadingTransactions}`);

            poTransactions.forEach((transaction, index) =>
            {
                // Update the IDs of the items that were Added
                if (transaction.type === PurchaseOrderTransactionActionType.Add)
                {
                    const updatedId = resultIds[index];
                    const list = this.getListForPartType(transaction.partType) as any[];
                    const item = list.find(i => i.id === transaction.transactionId);
                    if (item != null) item.id = updatedId;
                }

                // Update
                this.messageService.add({
                    severity: 'success',
                    summary: 'PO Update Succeeded',
                    detail: PurchaseOrderManagerService.getMessageStringForTransaction(transaction),
                });
            });

            LocalCacheManager.transactionQueue = this.transactionQueue;

            return this.processTransactionQueue();
        }), catchError(() =>
        {
            this.messageService.add({
                severity: 'error', summary: 'Update Error', life: 5000,
                detail: 'There was an error updating the Purchase Order. To try again, go to the Purchase Order details and click the refresh button.'
            });
            return of(false);
        }));
    }

    private addTransactionToQueue(transaction: PurchaseOrderTransaction): number
    {
        transaction.transactionId = LocalCacheManager.nextTransactionQueueItemId;
        this.transactionQueue.push(transaction);
        LocalCacheManager.transactionQueue = this.transactionQueue;
        return transaction.transactionId;
    }

    private updatePOAndProcessQueue(): Observable<boolean>
    {
        this._current.updateQuantitiesAndPrices();
        LocalCacheManager.currentPO = this._current;

        return this.processTransactionQueue();
    }
    //endregion

    //region Utility Functions
    private getListForPartType(partType: PurchaseOrderTransactionPartType): PurchaseOrderLineItem[] | PurchaseOrderLineItemMiscellaneous[]
    {
        switch (partType)
        {
            case PurchaseOrderTransactionPartType.Converter: return this._current.lineItemsConverter;
            case PurchaseOrderTransactionPartType.Custom: return this._current.lineItemsCustom;
            case PurchaseOrderTransactionPartType.NoNumber: return this._current.lineItemsNoNumber;
            case PurchaseOrderTransactionPartType.Category: return this._current.lineItemsCategory;
            case PurchaseOrderTransactionPartType.Miscellaneous: return this._current.lineItemsMiscellaneous;
        }
    }
    //endregion
    private addTransactionItemToPO(converter: Converter, converterGroupName: string, transactionId: number,
                                   transaction: PurchaseOrderTransaction): void
    {
        const list = this.getListForPartType(transaction.partType);
        // Complicated code to find existing item. Here are the rules:
        // Regular converter: existing item has the same converter ID and the same levelPercentage
        // Category converter: existing item has the same converter ID and the same pricePerItem and the same levelPercentage
        // Custom converter: existing item has the same converter ID and the same pricePerItem
        const existingItemIndex = transaction.partType === PurchaseOrderTransactionPartType.Converter
            ? list.findIndex(li => li.converterId === converter.id && li.levelPercentage === transaction.levelPercentage &&
                li.pricePerItem === transaction.pricePerItem)
            : transaction.partType === PurchaseOrderTransactionPartType.NoNumber
                ? list.findIndex(li => li.converterId === converter.id && li.levelPercentage === transaction.levelPercentage &&
                    li.pricePerItem === transaction.pricePerItem)
                : transaction.partType === PurchaseOrderTransactionPartType.Category
                    ? list.findIndex(li => li.converterId === converter.id && li.pricePerItem === transaction.pricePerItem &&
                        li.categoryHasCustomPrices === transaction.categoryHasCustomPrices &&
                        li.levelPercentage === transaction.levelPercentage)
                    : transaction.partType === PurchaseOrderTransactionPartType.Custom
                        ? list.findIndex(li => li.converterId === converter.id && li.pricePerItem === transaction.pricePerItem)
                        : -1;
        if (existingItemIndex !== -1)
        {
            console.log('existing item');
            const existingItem = list[existingItemIndex];
            existingItem.quantity += transaction.quantity;
            existingItem.lastUpdated = new Date();
            existingItem.updateTotalPrice();

            // Move to top of list
            list.splice(existingItemIndex, 1);
            list.unshift(existingItem as any);
        }
        else
        {
            console.log('new item');
            const newItem = new PurchaseOrderLineItem({
                id: transactionId, converterId: converter.id, converterGroupName, converterPartNumber: converter.partNumber,
                type: transaction.partType, quantity: transaction.quantity, pricePerItem: transaction.pricePerItem,
                levelPercentage: transaction.levelPercentage, byPound: transaction.byPound,
                categoryHasCustomPrices: transaction.partType === PurchaseOrderTransactionPartType.Category
                    ? transaction.categoryHasCustomPrices : false,
            });
            newItem.lastUpdated = new Date();
            newItem.updateTotalPrice();
            list.unshift(newItem as any);
        }
        this._current.updateQuantitiesAndPrices();
        this.updateCurrent(this._current);
    }

    private deleteLineItemFromPO(partType: PurchaseOrderTransactionPartType,
                                 item: PurchaseOrderLineItem | PurchaseOrderLineItemMiscellaneous): void
    {
        switch (partType)
        {
            case PurchaseOrderTransactionPartType.Converter:
                this._current.lineItemsConverter = this._current.lineItemsConverter.filter(li => li.id !== item.id); break;
            case PurchaseOrderTransactionPartType.Custom:
                this._current.lineItemsCustom = this._current.lineItemsCustom.filter(li => li.id !== item.id); break;
            case PurchaseOrderTransactionPartType.NoNumber:
                this._current.lineItemsNoNumber = this._current.lineItemsNoNumber.filter(li => li.id !== item.id); break;
            case PurchaseOrderTransactionPartType.Category:
                this._current.lineItemsCategory = this._current.lineItemsCategory.filter(li => li.id !== item.id); break;
            default:
                this._current.lineItemsMiscellaneous = this._current.lineItemsMiscellaneous.filter(li => li.id !== item.id); break;
        }
        this._current.updateQuantitiesAndPrices();
        this.updateCurrent(this._current);
    }

    private hasUpdateConflict(item: PurchaseOrderLineItem): boolean
    {
        if (item.type === PurchaseOrderTransactionPartType.Converter &&
            this._current.lineItemsConverter.findIndex(li => li.id !== item.id && li.converterId === item.converterId &&
                li.levelPercentage === item.levelPercentage) !== -1)
        {
            this.messageService.add({
                severity: 'error',
                summary: 'Metal Quantity Conflict',
                detail: 'There is already a Converter with that Metal Quantity.',
                life: 5000,
            });
            return true;
        }

        if (item.type === PurchaseOrderTransactionPartType.NoNumber &&
            this._current.lineItemsNoNumber.findIndex(li => li.id !== item.id && li.converterId === item.converterId &&
                li.levelPercentage === item.levelPercentage) !== -1)
        {
            this.messageService.add({
                severity: 'error',
                summary: 'Metal Quantity Conflict',
                detail: 'There is already a No-Number Converter with that Metal Quantity.',
                life: 5000,
            });
            return true;
        }

        if (item.type === PurchaseOrderTransactionPartType.Category &&
            this._current.lineItemsCategory.findIndex(li => li.id !== item.id && li.converterId === item.converterId &&
                !item.categoryHasCustomPrices && !li.categoryHasCustomPrices && li.levelPercentage === item.levelPercentage) !== -1)
        {
            this.messageService.add({
                severity: 'error',
                summary: 'Metal Quantity Conflict',
                detail: 'There is already a Category Converter with that Metal Quantity.',
                life: 5000,
            });
            return true;
        }

        return false;
    }

    private updateItemInPO(item: PurchaseOrderLineItem): void
    {
        // Replace the item in the list
        item.updateTotalPrice();
        item.lastUpdated = new Date();
        const list = this.getListForPartType(item.type);
        list[list.findIndex(li => li.id === item.id)] = item;
        this._current.updateQuantitiesAndPrices();
        this.updateCurrent(this._current);
    }

    private handleTransactionError(): void
    {
        this.confirmationService.confirm({
            message: 'There was an error updating the Purchase Order. Please make sure you are connected to the Internet then click OK. The PO will be reloaded to ensure you have the latest data.',
            header: 'Update Error',
            icon: 'pi pi-exclamation-circle',
            key: 'okOnly',
            accept: () =>
            {
                setTimeout(() => // To allow other confirmation dialog to close before continuing.
                {
                    const id = this.current.id;
                    this.setCurrentPO(null).subscribe();
                    this.setCurrentPO(id).subscribe({
                            next: () => {},
                            error: () =>
                            {
                                this.confirmationService.confirm({
                                    message: 'The PO could not be reloaded. Check your Internet connection then reload the PO.',
                                    header: 'Update Error',
                                    icon: 'pi pi-exclamation-circle',
                                    key: 'okOnly',
                                    accept: () => {},
                                });
                            }
                        }
                    );
                }, 100);
        }});
    }
}
