import { User } from '../user/user.model';

export class AuthenticatedUser
{
    additionalLocationIds: number[];
    chooseSubsidiaries = false;
    exemptFromVendorLicenseRequirements = false;
    expiration: Date;
    firstName: string;
    hasAcceptedTermsOfUse = false;
    hasAssayAsAdditionalLocation = false;
    id: string;
    isAssayUser = false;
    isGlobalAdmin = false;
    isLocationAccountant = false;
    isLocationAdmin = false;
    isVendorAdmin = false;
    isLimitedAdmin = false;
    lastName: string;
    locationId = 0;
    locationName;
    purchaseOrderLocationName: string;
    purchaseOrderAddress: string;
    purchaseOrderCity: string;
    purchaseOrderState: string;
    purchaseOrderZip: string;
    refreshToken: string = null;
    refreshTokenExpiration: Date;
    roles: string[] = [];
    subsidiaryId = 0;
    token = '';
    twoFactorToken: string;
    vendorId: number;

    editUserList = false;
    editUserProperties = false;
    editUserPermissions = false;
    assignUserToLocation = false;
    assignBuyerToVendor = false;

    setPricesForLocation = false;
    setPricesForVendor = false;
    setPricesForBuyer = false;
    setPricesForAssayVendors = false;
    setPricesForConventionalVendors = false;

    setProfitMarginGlobal = false;
    setProfitMarginForBuyer = false;
    hideMarketPricing = false;
    setProfitMarginOnly = false;

    createHedge = false;
    assignHedgeToBuyer = false;

    // createVendor = false;
    deleteVendor = false;
    editVendorProperties = false;
    // updateVendorLicenseInfo = false;
    assignVendorAsAssay = false;
    allowVendorToCreatePOs = false;

    createPurchaseOrder = false;
    deletePurchaseOrder = false;
    unlockPurchaseOrder = false;
    exportPurchaseOrder = false;

    editPartsList = false;
    viewMiscParts = false;
    searchByVIN = false;
    viewCategoryParts = false;
    viewNoNumberParts = false;
    viewPriceSheet = false;
    viewNoNumberLink = false;
    viewCalculator = false;

    generateReports = false;
    createLogs = false;
    viewAllPOsForLocation = false;
    viewMap = false;
    viewTasks = false;
    viewVendorsAndUsers = false;

    constructor()
    {
    }

    public setAuthenticatedUser(json: any = null): void
    {
        this.chooseSubsidiaries = json.ChooseSubsidiaries === 'True';
        this.twoFactorToken = json.twoFactorToken;
        if (this.twoFactorToken != null) {
            return;
        }
        this.firstName = json.given_name;
        this.lastName = json.family_name;
        this.id = json.sid;
        this.subsidiaryId = json.SubsidiaryId == null ? 0 : +json.SubsidiaryId;
        this.locationId = json.LocationId == null ? 0 : +json.LocationId;
        this.vendorId = json.VendorId == null ? null : +json.VendorId;
        this.locationName = json.LocationName;
        this.purchaseOrderLocationName = json.PurchaseOrderLocationName;
        this.purchaseOrderAddress = json.PurchaseOrderAddress;
        this.purchaseOrderCity = json.PurchaseOrderCity;
        this.purchaseOrderState = json.PurchaseOrderState;
        this.purchaseOrderZip = json.PurchaseOrderZip;
        this.additionalLocationIds = json.AdditionalLocationIds == null ? [] :
            json.AdditionalLocationIds.toUpperCase().split(',').map(s => +s);
        this.roles = json.roles == null ? [] : json.roles.toUpperCase().split(',');
        this.token = json.token;
        this.refreshToken = json.refreshToken;
        this.exemptFromVendorLicenseRequirements = json.ExemptFromVendorLicenseRequirements === 'True';
        this.expiration = json.expiration;
        this.refreshTokenExpiration = json.refreshTokenExpiration;

        this.hasAcceptedTermsOfUse = json.HasAcceptedTermsOfUse === 'True';
        this.hasAssayAsAdditionalLocation = json.HasAssayAsAdditionalLocation === 'True';
        this.isAssayUser = json.IsAssayUser === 'True';
        this.isGlobalAdmin = json.IsGlobalAdmin === 'True';
        this.isLocationAccountant = json.IsLocationAccountant === 'True';
        this.isLocationAdmin = json.IsLocationAdmin === 'True';
        this.isVendorAdmin = json.IsVendorAdmin === 'True';
        this.isLimitedAdmin = json.IsLimitedAdmin === 'True';
        this.editUserList = json.EditUserList === 'True';
        this.editUserProperties = json.EditUserProperties === 'True';
        this.editUserPermissions = json.EditUserPermissions === 'True';
        this.assignUserToLocation = json.AssignUserToLocation === 'True';
        this.assignBuyerToVendor = json.AssignBuyerToVendor === 'True';

        this.setPricesForLocation = json.SetPricesForLocation === 'True';
        this.setPricesForVendor = json.SetPricesForVendor === 'True';
        this.setPricesForBuyer = json.SetPricesForBuyer === 'True';
        this.setPricesForAssayVendors = json.SetPricesForAssayVendors === 'True';
        this.setPricesForConventionalVendors = json.SetPricesForConventionalVendors === 'True';

        this.setProfitMarginGlobal = json.SetProfitMarginGlobal === 'True';
        this.setProfitMarginForBuyer = json.SetProfitMarginForBuyer === 'True';

        this.hideMarketPricing = json.HideMarketPricing === 'True';
        this.setProfitMarginOnly = json.SetProfitMarginOnly === 'True';

        this.createHedge = json.CreateHedge === 'True';
        this.assignHedgeToBuyer = json.AssignHedgeToBuyer === 'True';

        // this.createVendor = json.CreateVendor === 'True';
        this.deleteVendor = json.DeleteVendor === 'True';
        this.editVendorProperties = json.EditVendorProperties === 'True';
        // this.updateVendorLicenseInfo = json.UpdateVendorLicenseInfo === 'True';
        this.assignVendorAsAssay = json.AssignVendorAsAssay === 'True';
        this.allowVendorToCreatePOs = json.AllowVendorToCreatePOs === 'True';

        this.createPurchaseOrder = json.CreatePurchaseOrder === 'True';
        this.deletePurchaseOrder = json.DeletePurchaseOrder === 'True';
        this.unlockPurchaseOrder = json.UnlockPurchaseOrder === 'True';
        this.exportPurchaseOrder = json.ExportPurchaseOrder === 'True';

        this.editPartsList = json.EditPartsList === 'True';
        this.viewMiscParts = json.ViewMiscParts === 'True';
        this.viewCategoryParts = json.ViewCategoryParts === 'True';
        this.viewNoNumberParts = json.ViewNoNumberParts === 'True';
        this.viewPriceSheet = json.ViewPriceSheet === 'True';
        this.viewNoNumberLink = json.ViewNoNumberLink === 'True';
        this.viewCalculator = json.ViewCalculator === 'True';
        this.generateReports = json.GenerateReports === 'True';
        this.createLogs = json.CreateLogs === 'True';
        this.viewTasks = json.ViewTasks === 'True';
        this.viewMap = json.ViewMap === 'True';
        this.searchByVIN = json.SearchByVIN === 'True';
        this.viewVendorsAndUsers = json.ViewVendorsAndUsers === 'True';
        this.viewAllPOsForLocation = json.ViewAllPOsForLocation === 'True';
    }

    get isNotAssayUser(): boolean { return !this.isAssayUser; }
    get isBuyer(): boolean { return User.isBuyer(this.roles); }
    get isLsrAdmin(): boolean { return this.isGlobalAdmin || this.isLocationAdmin; }
    get isLsrOrLimitedAdmin(): boolean { return this.isGlobalAdmin || this.isLocationAdmin || this.isLimitedAdmin; }
    get isAnyFullAdmin(): boolean { return this.isGlobalAdmin || this.isLocationAdmin || this.isVendorAdmin; }
    get isAccounting(): boolean { return this.isGlobalAdmin || this.isLocationAdmin || this.isLocationAccountant; }
    get isLegendEmployee(): boolean { return this.vendorId == null; }

    public clearAuthenticatedUser(): void
    {
        this.firstName = '';
        this.lastName = '';
        this.locationId = 0;
        this.roles = [];
        this.token = '';
        this.refreshToken = '';
        this.expiration = null;

        this.editUserList = false;
        this.editUserProperties = false;
        this.editUserPermissions = false;
        this.assignUserToLocation = false;
        this.assignBuyerToVendor = false;

        this.setPricesForLocation = false;
        this.setPricesForVendor = false;
        this.setPricesForBuyer = false;
        this.setPricesForAssayVendors = false;
        this.setPricesForConventionalVendors = false;

        this.setProfitMarginGlobal = false;
        this.setProfitMarginForBuyer = false;

        this.createHedge = false;
        this.assignHedgeToBuyer = false;

        // this.createVendor = false;
        this.deleteVendor = false;
        // this.updateVendorLicenseInfo = false;
        this.assignVendorAsAssay = false;
        this.allowVendorToCreatePOs = false;

        this.createPurchaseOrder = false;
        this.deletePurchaseOrder = false;
        this.unlockPurchaseOrder = false;
        this.exportPurchaseOrder = false;

        this.editPartsList = false;
        this.viewMiscParts = false;
        this.searchByVIN = false;
        this.viewCategoryParts = false;
        this.viewPriceSheet = false;
    }

    get fullName(): string { return `${this.firstName} ${this.lastName}`; }

    get isLoggedIn(): boolean
    {
        return this.hasRefreshToken && !this.isRefreshTokenExpired;
    }

    get canViewVendorList(): boolean
    {
        return this.editVendorProperties || this.deleteVendor;
    }

    get canViewUserList(): boolean
    {
        return this.editUserList || this.editUserPermissions;
    }

    get canViewPricingPage(): boolean
    {
        return this.setPricesForLocation || this.setPricesForVendor || this.setPricesForBuyer || this.setPricesForAssayVendors ||
            this.setPricesForConventionalVendors;
    }

    get canViewHedgesPage(): boolean
    {
        return (this.createHedge || this.assignHedgeToBuyer) && !this.isVendorAdmin;
    }

    private get hasRefreshToken(): boolean
    {
        return this.refreshToken != null && this.refreshToken.length > 0;
    }

    private get isRefreshTokenExpired(): boolean
    {
        if (this.refreshTokenExpiration == null)
        {
            return true;
        }
        return this.refreshTokenExpiration < new Date();
    }

    get purchaseOrderCityStateZip(): string
    {
        if (this.purchaseOrderLocationName == null) return null;
        let cityStateZip = this.purchaseOrderCity || '';
        cityStateZip += (cityStateZip.length === 0 ? '' : ', ') + (this.purchaseOrderState || '');
        cityStateZip += (cityStateZip.length === 0 ? '' : ' ') + (this.purchaseOrderZip || '');
        return cityStateZip;
    }
}
