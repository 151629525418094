import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AuthenticatedUser } from '../../services/auth/authenticated-user.model';
import { AuthManagerService } from '../../services/auth/auth-manager.service';
import { DeviceManagerService } from '../../core/services/device-manager.service';
import { BroadcastService } from '../../services/broadcast.service';
import { VendorManagerService } from '../../services/vendor/vendor-manager.service';
import { ThemeManager } from '../../managers/theme.manager';
import { environment } from '../../../environments/environment';

@UntilDestroy()
@Component({
    selector: 'ls-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit, OnDestroy
{
    @HostBinding('class') get classes(): string { return ThemeManager.theme; }

    @Input() hideNavigation = false;

    currentUser: AuthenticatedUser;
    hideHeader = true;
    isExpanded = false;
    deploymentName = environment.deploymentName;
    userMenuItems: MenuItem[];

    ThemeManager = ThemeManager;
    private currentUrl: string;

    constructor(public deviceManager: DeviceManagerService,
                private router: Router,
                private broadcastService: BroadcastService,
                private authManager: AuthManagerService,
                private vendorManager: VendorManagerService) { }

    ngOnInit(): void
    {
        this.authManager.currentUserChanged.pipe(untilDestroyed(this)).subscribe(() => this.updateLabels());
        this.userMenuItems = [
            {
                label: this.currentUser == null ? 'User' : this.currentUser.fullName || this.currentUser.firstName == null ? 'User' :
                    this.currentUser.firstName + ' ' +
                    (this.currentUser.firstName.length + this.currentUser.lastName.length < 15 ? this.currentUser.lastName : ''),
                items: [
                    // { label: 'Change Password', command: () => {
                    //         this.isExpanded = false;
                    //         this.router.navigate([ '/auth/change-password' ]);
                    //     } },
                    { label: 'Profile', command: () => {
                            this.isExpanded = false;
                            this.router.navigate([ '/auth/profile' ]);
                        } },
                    { label: 'Logout', command: () => {
                            this.isExpanded = false;
                            this.authManager.logout();
                        } },
                ]
            },
        ];
        this.updateLabels();

        this.broadcastService.displayPurchaseOrder.subscribe(() => this.isExpanded = false);

        this.router.events.pipe(untilDestroyed(this), filter(event => event instanceof NavigationEnd)).subscribe(event =>
        {
            this.currentUrl = (event as NavigationEnd).url;
            this.hideHeader = this.currentUrl.includes('/welcome') || this.currentUrl.includes('/termsOfUse') ||
                this.currentUrl.includes('/twoFactor') || this.currentUrl.includes('password') || this.currentUrl.includes('auth/invite')
                || this.currentUrl.includes('auth/create-account');
        });
    }

    ngOnDestroy(): void {
        /* Implemented to support untilDestroyed */
    }

    activeLinkClass(route: string): boolean {
        if (!this.currentUrl.includes('/admin')) return false;

        const checkingNonAdminRoute = route === '/admin/vendors';
        const isNonAdminRoute = this.currentUrl === '/admin/vendors';
        return checkingNonAdminRoute ? isNonAdminRoute : true;
    }

    menuItemClicked(): void
    {
        this.isExpanded = false;
    }

    get isConvertersActive(): boolean
    {
        return this.router.url.indexOf('/converters') !== -1 && this.router.url.indexOf('non-ferrous') === -1;
    }
    get isNonFerrousActive(): boolean { return this.router.url.indexOf('non-ferrous') !== -1; }

    logOut(): void
    {
        this.menuItemClicked();
        this.authManager.logout();
    }

    private updateLabels(): void
    {
        this.currentUser = this.authManager.currentUser;
        this.userMenuItems[0].label = this.currentUser == null ? 'User' : this.currentUser.firstName;

        this.deploymentName = environment.deploymentName;
        if (!this.currentUser.isAssayUser) return;

        this.vendorManager.getAllVendors(this.currentUser.locationId).subscribe(vendors =>
        {
            const vendor = vendors.vendors.find(v => v.id === this.currentUser.vendorId);
            if (vendor != null) this.deploymentName = vendor.name;
        });
    }
}
