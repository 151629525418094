import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { AuthenticatedUser } from '../../../services/auth/authenticated-user.model';
import { MenuItem } from 'primeng/api';

import { DeviceManagerService } from '../../../core/services/device-manager.service';
import { AuthManagerService } from '../../../services/auth/auth-manager.service';
import { SubsidiaryManagerService } from '../../../services/subsidiary/subsidiary-manager.service';
import { Subsidiary } from '../../../services/subsidiary/subsidiary.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'ls-subsidiary-toolbar',
    templateUrl: './subsidiary-toolbar.component.html',
    styleUrls: [ './subsidiary-toolbar.component.less' ]
})
export class SubsidiaryToolbarComponent implements OnInit, OnDestroy
{
    @HostBinding('class') get classes(): string { return 'dark'; }

    currentUser: AuthenticatedUser;
    subsidiaryItems: MenuItem[];

    private subsidiaries: Subsidiary[] = [];

    constructor(public deviceManager: DeviceManagerService,
                private authManager: AuthManagerService,
                private subsidiaryManager: SubsidiaryManagerService) { }

    ngOnInit(): void
    {
        this.authManager.currentUserChanged.pipe(untilDestroyed(this)).subscribe(user => {
            this.currentUser = user;
        });
        this.currentUser = this.authManager.currentUser;
        this.loadMenuItems();
    }

    ngOnDestroy(): void {
        /* Implemented to support untilDestroyed */
    }

    loadMenuItems(): void
    {
        if (this.subsidiaryItems != null) return;

        this.subsidiaryItems = [ { label: 'Loading subsidiaries...', items: [] } ];
        this.subsidiaryManager.getSubsidiaries().subscribe(subsidiaries =>
        {
            this.subsidiaries = subsidiaries;
            this.subsidiaryItems = subsidiaries.map(subsidiary =>
            {
                return { label: subsidiary.name, command: () => this.subsidiarySelected(subsidiary) };
            });
        });
    }

    get subsidiaryName(): string { return this.subsidiaries.find(s => s.id === this.authManager.currentUser.subsidiaryId)?.name; }

    private subsidiarySelected(subsidiary: Subsidiary): void
    {
        if (this.currentUser.subsidiaryId === subsidiary.id) return;
        this.authManager.loginWithRefreshToken(subsidiary.id, undefined).subscribe();
    }
}
