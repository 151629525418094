import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthManagerService } from '../../../services/auth/auth-manager.service';
import { AuthenticatedUser } from '../../../services/auth/authenticated-user.model';
import { LocationManagerService } from '../../../services/location/location-manager.service';
import { Location } from '../../../services/location/location.model';
import { MenuItem } from 'primeng/api';
import { DeviceManagerService } from '../../../core/services/device-manager.service';


@UntilDestroy()
@Component({
    selector: 'ls-location-toolbar',
    templateUrl: './location-toolbar.component.html',
    styleUrls: [ './location-toolbar.component.less' ]
})
export class LocationToolbarComponent implements OnInit, OnDestroy
{
    @HostBinding('class') get classes(): string { return 'dark'; }

    currentUser: AuthenticatedUser;
    locationItems: MenuItem[];

    constructor(public deviceManager: DeviceManagerService,
                private authManager: AuthManagerService,
                private locationManager: LocationManagerService) { }

    ngOnInit(): void
    {
        this.authManager.currentUserChanged.pipe(untilDestroyed(this)).subscribe(user => {
            this.currentUser = user;
            this.locationItems = null;
        });
        this.currentUser = this.authManager.currentUser;
    }

    ngOnDestroy(): void {
        /* Implemented to support untilDestroyed */
    }

    loadMenuItems(): void
    {
        if (this.locationItems != null) return;

        this.locationItems = [ { label: 'Loading locations...', items: [] } ];
        this.locationManager.getLocations().subscribe(locations => this.locationItems = locations.map(location =>
        {
            return { label: location.name, command: () => this.locationSelected(location) };
        }));
    }

    private locationSelected(location: Location): void
    {
        if (this.currentUser.locationId === location.id) return;
        this.authManager.loginWithRefreshToken(this.currentUser.subsidiaryId, location.id).subscribe();
    }
}
