import { Subsidiary } from '../subsidiary/subsidiary.model';

export class Location
{
    public announcementHeadingHtml: string;
    public announcementTextHtml: string;
    public newsHeadingHtml: string;
    public newsTextHtml: string;
    public id = 0;
    public latitude?: number;
    public longitude?: number;
    public name: string;
    public address: string;
    public city: string;
    public state: string;
    public zip: string;
    public subsidiary: Subsidiary;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
        }
    }

    get cityStateZip(): string
    {
        let cityStateZip = this.city || '';
        cityStateZip += (cityStateZip.length === 0 ? '' : ', ') + (this.state ? this.state : '');
        cityStateZip += (cityStateZip.length === 0 ? '' : ' ') + (this.zip ? this.zip : '');
        return cityStateZip;
    }

    get fullAddress(): string { return `${this.address} ${this.cityStateZip}`; }

    clearLocation(): void
    {
        this.id = 0;
        this.name = null;
    }

    updateLocation(location: Location): void
    {
        Object.assign(this, location);
    }
}
